import React, { useRef } from "react";
import { Group as KonvaGroup, Rect as KonvaRect, Path } from 'react-konva';
import { pxToMm, mmToPx } from "../Converters";
import Barcode from "./Barcode";
import Image from "./Image";
import Text from "./Text";
import Rect from "./Rect";


const Group = ({ obj, updateObject, onDragStart, onDragEnd, onTransformEnd, viewOnly }) => {  
  const dragProps = onDragStart && onDragEnd ? {
    onDragStart,
    onDragEnd
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};

  
  const components = {
    "rect": Rect,
    "text": Text,
    "image": Image,
    "barcode": Barcode,
    "group": Group,
  };

  return (
    <KonvaGroup
      objectType={"group"}
      key={obj.id}
      id={obj.id}
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}      
      rotation={obj.rotation}
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragEnd}
      {...dragProps}
      {...transformProps}        
    >
      <KonvaRect
        objectType={"group"}
        width={mmToPx(obj.width)}
        height={mmToPx(obj.height)}
        fill={null}
        stroke={viewOnly ? null : "black"}
        strokeWidth={1}
        dash={[10, 10]}
      />
        {obj.objects.toReversed().map((innerObj) => {
          const Component = components[innerObj.type]

          if (!Component) return null;

          return (
            <Component
              obj={innerObj} 
              key={innerObj.id}
              updateObject={updateObject}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onTransformEnd={onTransformEnd}
            />
          )
        })}
    </KonvaGroup>
  );
};

export default Group;