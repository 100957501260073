import { Rect as KonvaRect } from 'react-konva';
import { pxToMm, mmToPx } from "../Converters";


const Rect = ({ obj, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly }) => {
  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};

  return (
    <KonvaRect
      objectType={"rect"}
      key={obj.id}
      id={obj.id}
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}      
      rotation={obj.rotation}
      fill={obj.fill}
      stroke={obj.stroke}
      strokeWidth={mmToPx(obj.strokeWidth)}
      strokeScaleEnabled={false}
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
    />
  )
}

export default Rect;