import { useRef, useEffect } from 'react';
import { Text as KonvaText } from 'react-konva';
import { pxToMm, mmToPx } from "../Converters";


const Text = ({ obj, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly }) => {  
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.setAttrs({
        fontFamily: obj.fontFamily,
        fontStyle: obj.fontStyle,
      });

      textRef.current.clearCache();
      textRef.current.getLayer().batchDraw();
    }
  }, [obj]);

  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const handleTransform = (e) => {
    const node = e.target;
    node.setAttrs({
      width: Math.max(node.width() * node.scaleX(), 20),
      height: Math.max(node.height() * node.scaleY(), 20),
      scaleX: 1,
      scaleY: 1
    });
  }
  
  const transformProps = onTransformEnd ? {
    onTransform: handleTransform,
    onTransformEnd
  } : {};


  return (
    <KonvaText
      ref={textRef}
      objectType={"text"}
      key={obj.id}
      id={obj.id}
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}      
      rotation={obj.rotation}
      text={Object.hasOwn(obj, "value") ? obj.value : obj.defaultValue}
      fontSize={obj.fontSize}
      fontFamily={obj.fontFamily}
      fontStyle={obj.fontStyle}
      textDecoration={obj.textDecoration}
      wrap={obj.wrap}
      wrapLines={obj.wrapLines}
      align={obj.align}
      strokeScaleEnabled={false}
      fill={obj.fill}
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}      
      {...dragProps}
      {...transformProps}
    />
  )
}

export default Text;