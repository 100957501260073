import React, { useState, useEffect } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";

const RectProperties = ({ selectedObject, updateObject }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;
    value = type === "text" ? e.target.value : Number(e.target.value);
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  const handleColorChange = (args) => {
    updateObject({
      ...selectedObject,
      stroke: args.currentValue.hex,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")} (mm):
          </label>
          <input
            name="width"
            type="number"
            value={selectedObject.width}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")} (mm):
          </label>
          <input
            name="height"
            type="number"
            value={selectedObject.height}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X (mm):</label>
          <input
            name="x"
            type="number"
            value={selectedObject.x}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y (mm):</label>
          <input
            name="y"
            type="number"
            value={selectedObject.y}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("borderWidthLabel")}:
          </label>
          <input
            name="strokeWidth"
            type="number"
            value={selectedObject.strokeWidth}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("borderColorLabel")}
          </label>
          <ColorPickerComponent
            id={"stroke-color-picker"}
            name="stroke"
            value={selectedObject.stroke}
            defaultValue={selectedObject.stroke}
            // mode="Palette"
            // noColor={false}
            showButtons={true}
            modeSwitcher={true}
            change={handleColorChange}
            locale={i18n.language}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
    </div>
  );
};

export default RectProperties;
