import React, { useEffect, useState, useRef } from "react";
import ImageService from "../../services/ImageService";
import BarcodeComponent from "./LabelComponentsForPreviews/BarcodeComponent";
import Rectangle from "./LabelComponentsForPreviews/Rectangle";
import Text from "./LabelComponentsForPreviews/Text";
import Repeater from "./LabelComponentsForPreviews/Repeater";
import Image from "./LabelComponentsForPreviews/Image";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import WaitIndicator from "../../components/WaitIndicator";
import styles from "./PrintLabelsToPDF2.module.css";
import { useTranslation } from "react-i18next";
import LabelViewerStage from "./LabelViewerStage";
import Checkbox from "../../components/Checkbox";
import generatePDF from "./generatePDF";
import { mmToPx } from "./Converters";
import AntSlider from "../../components/Ant/AntSlider/AntSlider";

const PrintLabelsToPDF2 = ({
  user,
  arrayOfRowsForPrint,
  label,
  setLabel,
  selectedDeviceCanisters,
  onPrintLabelsToPDF,
  firstDevicePosition,
  lastDevicePosition,
  arrayOfDevicePositions,
  ignoreEmptyCanisters,
  autoLayout,
  copies,
}) => {
  const { t } = useTranslation("printLabels");

  const stageRef = useRef(null);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [mappedLabels, setMappedLabels] = useState([]);
  const [canisterPositions, setCanisterPositions] = useState([]);
  const [selectedCanisters, setSelectedCanisters] = useState([]);
  const [selectedCanisterPosition, setSelectedCanisterPosition] =
    useState(firstDevicePosition);
  const [showCanvaText, setShowCanvaText] = useState(false);

  const [generatePDFLabels, setGeneratePDFLabels] = useState(false);

  const [barcodeStringSVGS, setBarcodeStringSVGS] = useState([]);

  const [productImages, setProductImages] = useState({});
  const [readyToDisplayPrintOption, setReadyToDisplayPrintOption] =
    useState(false);

  const [previewOutline, setPreviewOutline] = useState(false);
  const [previewPosition, setPreviewPosition] = useState(false);
  const [convertText, setConvertText] = useState(false);

  const [zoom, setZoom] = useState(100);
  const [areaWidth, setAreaWidth] = useState(null);

  useEffect(() => {
    const calculateZoom = () => {
      const availableWidth = window.innerWidth - 280; // navbar 120, home padding 60*2, buffer 40        
      const calculatedZoom = Math.floor(((availableWidth / mmToPx(label.sheetWidth)) * 100 - 5) / 5) * 5;
      
      setAreaWidth(availableWidth)
      setZoom(calculatedZoom);
    };

    calculateZoom();
    window.addEventListener('resize', calculateZoom);

    return () => window.removeEventListener('resize', calculateZoom);
  }, [label.sheetWidth]);

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  useEffect(() => {
    console.log("ML", mappedLabels);
  }, [mappedLabels]);



  useEffect(() => {           
    let tempSelectedCanisters;

    if (arrayOfDevicePositions.length) {
      tempSelectedCanisters = selectedDeviceCanisters.filter((canister) => {
        return arrayOfDevicePositions.includes(canister.devicePosition);
      });
    } else {
      tempSelectedCanisters = selectedDeviceCanisters.slice(
        firstSelectedCanisterIndex,
        lastSelectedCanisterIndex + 1
      );
    } 

    const tempCanisterPositions = [];
    tempSelectedCanisters.forEach((canister, index) => {
      if (ignoreEmptyCanisters && !canister.productAssociations.length) {
        return;
      }

      for (let copy = 0; copy < copies; copy++) {
        tempCanisterPositions.push(parseInt(canister.devicePosition));
      }
    });  
    
    const maxPage = Math.ceil(tempCanisterPositions.length / (label.rowCount * label.colCount))
    setPages(Array.from({ length: maxPage }, (_, i) => i + 1))
    setSelectedCanisters(tempSelectedCanisters)
    setCanisterPositions(tempCanisterPositions);    
  }, [])


  const getDrugsImages = async () => {
    console.log("getDrugsImages")
    if (Object.keys(productImages).length) {
      return;
    }
    
    const tempImages = {};
    for (const canisterPosition of canisterPositions) {
      const selectedDeviceCanister = selectedDeviceCanisters.find(
        (o) => parseInt(o.devicePosition) === canisterPosition
      );
      if (selectedDeviceCanister.productAssociations[0]?.product?.images[0]) {
        const image = await getDrugImage(
          user,
          selectedDeviceCanister.productAssociations[0].product.images[0]
        );
        tempImages[canisterPosition] = {
          base64EncodedString: image.base64EncodedString,
          mimeType: image.mimeType,
        }
      } else {
        tempImages[canisterPosition] = {
          ignoreImage: true,
        }
      }
    }
    
    console.log("set images", tempImages)
    setProductImages(tempImages);
    setReadyToDisplayPrintOption(true);
  };

  const getDrugImage = async (user, imageId) => {
    try {
      const image = await ImageService.read(user, imageId);
      return image;
    } catch (error) {
      console.error("Error getting drug image", error);
      return false;
    }
  };

  useEffect(() => {
    console.log("useEffect [canisterPositions, images]")
    if (canisterPositions.length) {
      getDrugsImages();
    }
  }, [canisterPositions]);

  useEffect(() => {
    console.log("useEffect [readyToDisplayPrintOption]")   
    if (readyToDisplayPrintOption) {
      console.log("images loaded", productImages)

      let svgs = document.querySelectorAll("[id^=svgBarcode]");
      let stringSVGS = [];
      for (let i = 0; i < svgs.length; i++) {
        if (svgs[i]) {
          stringSVGS.push(svgs[i].outerHTML);
        }
      }
      let filteredStringSVGS = stringSVGS.filter((svg) => {
        return svg.startsWith("<svg");
      });
      setBarcodeStringSVGS(filteredStringSVGS);
    }
  }, [readyToDisplayPrintOption, productImages]);

  const getDefaultName = (node) => {
    if (node.type === "text") {
      return node.defaultValue;
    } else if (node.type === "barcode") {
      return node.defaultValues[node.symbology] || "";
    } else {
      return "";
    }
  };

  const mapCanisterValue = (obj, canister) => {
    if (!obj.mappingName || (obj.type !== "text" && obj.type !== "barcode")) {
      return {};
    }

    let mappedValue = "";
    switch (obj.mappingPath) {
      case "":
        mappedValue = getDefaultName(obj);
        break;
      case "stockLocation.barcode":
        mappedValue = canister?.barcode || "";
        break;
      case "productAssociations[0].product.controlLevel.type":
        mappedValue =
          canister.productAssociations[0]?.product?.controlLevel?.type || "";
        break;
      case "productAssociations[0].product.comments":
        mappedValue = canister.productAssociations[0]?.product?.comments || "";
        break;
      case "productAssociations[0].product.dosageForm.type":
        mappedValue =
          canister.productAssociations[0]?.product?.dosageForm?.type || "";
        break;
      case "productAssociations[0].product.legend":
        mappedValue = canister.productAssociations[0]?.product?.legend || "";
        break;
      case "productAssociations[0].product.abbrName":
        mappedValue = canister.productAssociations[0]?.product?.abbrName || "";
        break;
      case "productAssociations[0].product.manufacturer.fullName":
        mappedValue =
          canister.productAssociations[0]?.product?.manufacturer?.fullName ||
          "";
        break;
      case "stockLocation.devicePosition":
        mappedValue = canister?.devicePosition || "";
        break;
      case "stockLocation.name":
        mappedValue = canister?.name || "";
        break;
      case "productAssociations[0].product.packageId":
        mappedValue = canister.productAssociations[0]?.product?.packageId || "";
        break;
      case "productAssociations[0].product.primaryName":
        mappedValue =
          canister.productAssociations[0]?.product?.primaryName || "";
        break;
      case "productAssociations[0].product.productId":
        mappedValue = canister.productAssociations[0]?.product?.productId || "";
        break;
      case "productAssociations[0].product.secondaryName":
        mappedValue =
          canister.productAssociations[0]?.product?.secondaryName || "";
        break;
      case "productAssociations[0].product.strength":
        mappedValue = canister.productAssociations[0]?.product?.strength || "";
        break;
      case "productAssociations[0].product.tallManName":
        mappedValue =
          canister.productAssociations[0]?.product?.tallManName || "";
        break;
      case "productAssociations[0].product.tertiaryName":
        mappedValue =
          canister.productAssociations[0]?.product?.tertiaryName || "";
        break;
      default:
        mappedValue = "";
    }
    return { value: mappedValue };
  };

  const mapCanisterImage = (obj, canister) => {
    if (obj.type !== "image") {
      return {};
    } else if (
      readyToDisplayPrintOption &&
      obj.getImagesFromDatabase &&
      canister.productAssociations[0]?.product?.images?.length
    ) {
      return {
        ignoreImage: false,
        ...productImages[Number(canister.devicePosition)],
      };
    } else if (
      obj.getImagesFromDatabase &&
      !canister.productAssociations[0]?.product?.images?.length
    ) {
      return {
        ignoreImage: true,
      };
    } else {
      return {};
    }
  };

  const mapLabelTemplate = (labelTemplate, canister) => {
    return labelTemplate.map((obj) => ({
      ...obj,
      ...mapCanisterValue(obj, canister),
      ...mapCanisterImage(obj, canister),
      canisterPosition: Number(canister.devicePosition)
    }));
  };

  const handlePrevious = () => {
    onPrintLabelsToPDF(false);
  };

  const firstSelectedCanisterIndex = parseInt(
    selectedDeviceCanisters.findIndex((selectedDeviceCanister) => {
      return (
        parseInt(selectedDeviceCanister.devicePosition) ===
        parseInt(firstDevicePosition)
      );
    })
  );

  const lastSelectedCanisterIndex = parseInt(
    selectedDeviceCanisters.findIndex((selectedDeviceCanister) => {
      return (
        parseInt(selectedDeviceCanister.devicePosition) ===
        parseInt(lastDevicePosition)
      );
    })
  );


  /**
  const buildNodesArray = (node, parent) => {
    // RECURSIVE CASE
    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node); // Traverse child nodes
    }
    if (parent) {
      node.parent = parent;
    }
    // BASE CASE
    return;
  };
   */

  let imageArrayIndex = -1;
  let canisterName = "";

  useEffect(() => {
    if (readyToDisplayPrintOption) {
      console.log("useEffect2 [readyToDisplayPrintOption]")
      const tempMappedLabels = [];
  
      selectedCanisters.forEach((canister, index) => {
        if (ignoreEmptyCanisters && !canister.productAssociations.length) {
          return;
        }
        const mappedLabelTemplate = mapLabelTemplate(
          label.template,
          canister
        );
  
        for (let copy = 0; copy < copies; copy++) {
          tempMappedLabels.push(mappedLabelTemplate);
        }
      });
  
      setMappedLabels(tempMappedLabels);
    }
  }, [readyToDisplayPrintOption]);

  const handleSelectedCanisterPosition = (position) => {
    setSelectedCanisterPosition(position.toString());
  };

  const paginationStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "#121a2d",
    minWidth: "24px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginLeft: "4px",
    marginRight: "4px",
    textAlign: "center",
    border: "1px solid",
    borderColor: "#00000033",
    cursor: "pointer",
  };

  // listed keys/values override those in paginationStyle when pagination pageValue is active
  const activePaginationStyle = {
    color: "#089bab",
    borderColor: "#089bab",
  };

  const prevNextStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "#121a2d",
    cursor: "pointer",
  };

  // listed keys/values override those in prevNextStyle when pagination pageValue is active
  const prevNextDisabledStyle = {
    color: "#00000033",
    cursor: "default",
  };

  const GetCanvasComponent = (children) => {
    return <div>{children}</div>;
  };

  const getRectangleComponent = (node, children, selectedObjectBorderColor) => {
    return (
      <Rectangle key={node.nodeId} node={node}>
        {children}
      </Rectangle>
    );
  };

  const getBarcodeComponent = (node, children) => {
    return (
      <BarcodeComponent key={node.nodeId} node={node}>
        {children}
      </BarcodeComponent>
    );
  };

  const getTextComponent = (node, children) => {
    return (
      <Text key={node.nodeId} node={node}>
        {children}
      </Text>
    );
  };

  const getRepeaterComponent = (
    node,
    children,
    ghost = false,
    ghostIndex = -1,
    copy = false
  ) => {
    return (
      <Repeater
        key={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        keyValue={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        node={node}
        children={children}
        ghost={ghost}
        ghostIndex={ghostIndex}
        copy={copy}
        arrayOfRowsForPrint={arrayOfRowsForPrint}
      >
        {children}
      </Repeater>
    );
  };

  const getImageComponent = (node, children) => {
    return (
      <Image key={node.nodeId} node={node}>
        {children}
      </Image>
    );
  };

  // Traverse node tree recursively, starting with label child nodes

  let nodeIds = [];

  const traverseLabelChildren = (node) => {
    if (node.childNodes.length && readyToDisplayPrintOption) {
      const NodeComponents = node.childNodes.map((childNode) => {
        if (childNode.type === "childRectangle") {
          return getRectangleComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        }
        if (childNode.type === "barcode") {
          return getBarcodeComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        } else if (childNode.type === "text") {
          return getTextComponent(childNode, traverseLabelChildren(childNode));
        } else if (childNode.type === "repeater") {
          let nodeFound = false;

          if (nodeIds.indexOf(childNode.nodeId.toString()) !== -1) {
            nodeFound = true;
          }

          let content;
          // Tailor here is using repeater approach for customized label
          if (
            label?.customizedLabel &&
            label.customizedLabel === "CindyR"
          ) {
            if (label.machineType === "TruScript") {
              content = [
                getRepeaterComponent(
                  childNode,
                  traverseLabelChildren(childNode),
                  false,
                  -1,
                  nodeFound
                ),
              ];
            } else {
              content = [];
            }

            for (let i = 0; i < childNode.repeatCount; i++) {
              if (
                (label.machineType === "TruCard" &&
                  (i === 1 || i === 4)) ||
                (label.machineType === "TruPak" &&
                  (i === 0 || i === 3)) ||
                (label.machineType === "TruScript" && i === 2)
              ) {
                content.push(
                  getRepeaterComponent(
                    childNode,
                    traverseLabelChildren(childNode),
                    true,
                    i,
                    true
                  )
                );
              }
            }
          } else {
            content = [
              getRepeaterComponent(
                childNode,
                traverseLabelChildren(childNode),
                false,
                -1,
                nodeFound
              ),
            ];

            for (let i = 0; i < childNode.repeatCount; i++) {
              content.push(
                getRepeaterComponent(
                  childNode,
                  traverseLabelChildren(childNode),
                  true,
                  i,
                  true
                )
              );
            }
          }
          return content;
        } else if (childNode.type === "image" && !childNode.ignoreImage) {
          return getImageComponent(childNode, traverseLabelChildren(childNode));
        }
      });
      return NodeComponents;
    } else {
      return;
    }
  };

  const mappedLabelIndex = mappedLabels.findIndex(
    (mappedLabel) => mappedLabel.canisterPosition === selectedCanisterPosition
  );

  const handleGeneratePDFLabels = (boolVal) => {
    setGeneratePDFLabels(boolVal);
  };

  if (!readyToDisplayPrintOption) {
    return <WaitIndicator message={t("fetching")} />;
  }

  return (
    <div className={styles.PrintLabelsToPDF__container}>
      <header>
        <p className={styles.PrintLabelsToPDF__heading}>{t("previewLabel")}</p>
      </header>
      {!readyToDisplayPrintOption && <WaitIndicator message={t("loading")} />}
      <div
        id="stageContainer"
        className={styles.PrintLabelsToPDF__stageContainer}
        style={{width: areaWidth}}
      >
        <div 
          id="stage"
          className={styles.PrintLabelsToPDF__stage}
        >  
          <LabelViewerStage   
            stageRef={stageRef}
            canisterObjects={mappedLabels}
            page={page}
            zoom={zoom}
            sheetWidth={label.sheetWidth}
            sheetHeight={label.sheetHeight}
            labelWidth={label.labelWidth}
            labelHeight={label.labelHeight}
            rowCount={label.rowCount}
            colCount={label.colCount}
            autoLayout={autoLayout}
            previewOutline={previewOutline}
            previewPosition={previewPosition}
          />
        </div>
      </div>
      {readyToDisplayPrintOption && (
        <div className={styles.PrintLabelsToPDF__optionsContainer}>          
          <div className={styles.PrintLabelsToPDF__paginationContainer}>
            <header>
              <p>{t("pagesLabel")}</p>
            </header>
            <Pagination
              pageValues={pages}
              onPageClick={(p) => setPage(p - 1)}
              maxPagesToDisplay={12}
              paginationStyle={paginationStyle}
              activePaginationStyle={activePaginationStyle}
              prevNextStyle={prevNextStyle}
              prevNextDisabledStyle={prevNextDisabledStyle}
            />
          </div>
          
          <div className={styles.PrintLabelsToPDF__zoom}>
            <label>{t("zoomLabel")}</label>          
            <AntSlider
              min={5}
              max={200}
              defaultValue={zoom}
              onChange={(e) => setZoom(Number(e))}
            />
          </div>
          <div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("previewOutlinesLabel")}
                isOn={previewOutline}
                onCheck={setPreviewOutline}
              />
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("previewPositionsLabel")}
                isOn={previewPosition}
                onCheck={setPreviewPosition}
              />
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("convertTextLabel")}
                isOn={convertText}
                onCheck={setConvertText}
              />
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={"DEBUG SHOW CANVAS TEXT"}
                isOn={showCanvaText}
                onCheck={setShowCanvaText}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.PrintLabelsToPDF__buttonsContainer}>
        <Button
          labelName={t("prevButtonLabel")}
          minWidth={"123px"}
          isPrimary={false}
          onClick={handlePrevious}
        />
        <Spacer space={20} unit={"px"} />
        <Button
          labelName={t("generateButtonLabel")}
          isPrimary={true}
          minWidth={"213px"}
          onClick={() => generatePDF(stageRef, pages, setPage, zoom, setZoom, convertText, showCanvaText)}
        />
      </div>
    </div>
  );
};

export default PrintLabelsToPDF2;
