import React from 'react';
import styles from './Properties.module.css';
import { useTranslation } from 'react-i18next';


const PrintProperties = ({ 
  width,
  setWidth,
  height,
  setHeight,
  rowCount,
  setRowCount,
  colCount,
  setColCount,
}) => {
  const { t } = useTranslation("labelEditor");

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("widthLabel")} (mm):</label>
          <input
            key={`stage-width`}
            name="width"
            type="number"
            value={width || 100}
            onChange={(e) => setWidth(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("heightLabel")} (mm):</label>
          <input
            key={`stage-height`}
            name="height"
            type="number"
            value={height || 100}
            onChange={(e) => setHeight(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>     
      </div>
      <div className={styles.Properties__row}>        
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("rowCountLabel")}:</label>
          <input
            key={`rowCount`}
            name="rowCount"
            type="number"
            value={rowCount}
            onChange={(e) => setRowCount(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>

        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("colCountLabel")}:</label>
          <input
            key={`colCount`}
            name="colCount"
            type="number"
            value={colCount}
            onChange={(e) => setColCount(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintProperties;
