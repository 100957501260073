import React, { useState, useRef, useEffect } from "react";
import Konva from 'konva';
import { Stage, Layer, Rect as KonvaRect, Transformer } from 'react-konva';
import { pxToMm, mmToPx } from "./Converters";
import "../../fonts/fontFaces.css";
import Barcode from "./StageComponents/Barcode";
import Image from "./StageComponents/Image";
import Text from "./StageComponents/Text";
import Rect from "./StageComponents/Rect";
import Group from "./StageComponents/Group";

Konva.pixelRatio = 4;

const LabelViewerStage = ({ 
  stageRef,
  canisterObjects, 
  zoom,
  sheetWidth,
  sheetHeight,
  labelWidth,
  labelHeight,
  rowCount,
  colCount,
  page,
  autoLayout,
  previewOutline,
  previewPosition,
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [layout, setLayout] = useState(null)

  useEffect(() => {
    if (autoLayout) {
      const horizontal = sheetWidth - (labelWidth * colCount);
      const vertical = sheetHeight - (labelHeight * rowCount);

      const xGap = horizontal / (colCount + 1);
      const yGap = vertical / (rowCount + 1);

      setLayout({
        top: yGap,
        right: xGap,
        bottom: yGap,
        left: xGap,
        rowGap: yGap,
        colGap: xGap
      })
    }
  }, [autoLayout, sheetWidth, sheetHeight, labelWidth, labelHeight, rowCount, colCount])


  const components = {
    "rect": Rect,
    "text": Text,
    "image": Image,
    "barcode": Barcode,
    "group": Group,
  };

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await document.fonts.ready;
        setFontsLoaded(true);
      } catch (error) {
        console.error("Font loading failed:", error);
      }
    };

    loadFonts();
  }, []);

  if (!fontsLoaded) {
    return <div></div>;
  }

  return (
    <Stage 
      ref={stageRef}
      container={"stage"}  
      width={mmToPx(sheetWidth) * zoom / 100} 
      height={mmToPx(sheetHeight) * zoom / 100}   
      scaleX={zoom / 100}
      scaleY={zoom / 100}
    >
      <Layer>
        <KonvaRect
          id="background"
          x={0}
          y={0}
          width={mmToPx(sheetWidth)}
          height={mmToPx(sheetHeight)}
          fill={"white"}
          listening={false}
        />
        {canisterObjects &&
          canisterObjects
            .slice(
              page * rowCount * colCount,
              (page + 1) * rowCount * colCount
            )
            .map((objects, i) => {
              const renderObjects = [...objects]

              if (previewOutline) {
                renderObjects.unshift({
                  type: "rect",
                  id: `outline-${i}`,
                  x: 0,
                  y: 0,
                  width: labelWidth,
                  height: labelHeight,
                  stroke: "red",
                  strokeWidth: 0.4,
                  fill: null,
                })
              }

              if (previewPosition) {
                renderObjects.unshift({
                  type: "text",
                  id: `position-${i}`,
                  x: 3,
                  y: 3,
                  width: 20,
                  height: 10,
                  fontSize: 42,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  textDecoration: "",
                  wrap: "none",
                  align: "left",
                  stroke: "none",
                  fill: "red",
                  defaultValue: objects[0].canisterPosition,
                })
              }

              return renderObjects.toReversed().map((obj) => {
                const Component = components[obj.type];
                if (!Component) return null;
                
                const col = (i % colCount)
                const row = (Math.floor(i / colCount) % rowCount);
             
                const tiledObj = {
                  ...obj,
                  x: obj.x + labelWidth * col + layout.left + layout.colGap * col,
                  y: obj.y + labelHeight * row + layout.top + layout.rowGap * row,
                }                

                return <Component obj={tiledObj} viewOnly={true} />;
              });
            })}
      </Layer>
    </Stage>
  );
};

export default LabelViewerStage;
