import PoppinsRegular from "./Poppins-Regular.ttf";
import PoppinsItalic from "./Poppins-Italic.ttf";
import PoppinsBold from "./Poppins-Bold.ttf";
import PoppinsBoldItalic from "./Poppins-BoldItalic.ttf";
import PoppinsRegular500 from "./Poppins-Medium.ttf";
import PoppinsRegular600 from "./Poppins-SemiBold.ttf";
import PoppinsRegular300 from "./Poppins-Light.ttf";
import RobotoRegular from "./Roboto-Regular.ttf";
import RobotoItalic from "./Roboto-Italic.ttf";
import RobotoBold from "./Roboto-Bold.ttf";
import RobotoBoldItalic from "./Roboto-BoldItalic.ttf";
import OpenSansRegular from "./OpenSans-Regular.ttf";
import OpenSansItalic from "./OpenSans-Italic.ttf";
import OpenSansBold from "./OpenSans-Bold.ttf";
import OpenSansBoldItalic from "./OpenSans-BoldItalic.ttf";

async function loadFontAsBinary(fontUrl) {
  try {
    const response = await fetch(fontUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    return uint8Array.reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    );
  } catch (error) {
    console.error("Error loading font:", error);
    return null;
  }
}

const addFontsToPdf = async (pdf) => {
  for (const font of ["Poppins", "Roboto", "Open Sans"]) {
    pdf.addFileToVFS(
      `${font.replace(" ", "")}-Regular.ttf`,
      await loadFontAsBinary(RobotoRegular)
    );
    pdf.addFont(`${font.replace(" ", "")}-Regular.ttf`, font, "normal");
  }
};

export default addFontsToPdf;
