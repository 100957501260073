import React, { useRef } from "react";
import { Group, Rect, Text, Path } from 'react-konva';
import { pxToMm, mmToPx } from "../Converters";
import bwipjs from 'bwip-js'; 


const Barcode = ({ obj, updateObject, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly }) => {  
  const symbologyRef = useRef(obj.symbology)
  
  if (Object.hasOwn(obj, "value") && !obj.value) {
    return null;
  }

  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};


  const svgString = bwipjs.toSVG({
    bcid:        obj.symbology,     
    text:        Object.hasOwn(obj, "value") ? obj.value : obj.defaultValues[obj.symbology],    
    //height:      12,           
    includetext: obj.showValue,           
    textxalign:  'center',       
    textcolor:   '000000',   
  });
  const template = document.createElement('template');
  template.innerHTML = svgString;  

  const svg = template.content.querySelector('svg');
  const viewBox = svg.getAttribute('viewBox').split(' ').map(Number);
  const originalWidth = viewBox[2];
  const originalHeight = viewBox[3];
  if (
    (!obj.width && !obj.height) ||
    symbologyRef.current !== obj.symbology
  ) {
    symbologyRef.current = obj.symbology
    updateObject({
      ...obj,
      width: pxToMm(originalWidth),
      height: pxToMm(originalHeight),
    });
  }

  const paths = template.content.querySelectorAll("svg path");

  const scaleX = mmToPx(obj.width) / originalWidth;
  const scaleY = mmToPx(obj.height) / originalHeight;

  return (
    <Group
      objectType={"barcode"}
      key={obj.id}
      id={obj.id}
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}
      rotation={obj.rotation}
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
    >
      <Rect
        objectType={"barcode"}
        width={mmToPx(obj.width)}
        height={mmToPx(obj.height)}
        fill="#ffffff"
      />
      {Array.from(paths).map((path, index) => {
        return (
          <Path
            objectType={"barcode"}
            key={`path${obj.id}-${index}`}
            id={`path${obj.id}-${index}`}
            data={path.getAttribute("d")}
            scaleX={scaleX}
            scaleY={scaleY}
            {...(path.getAttribute("fill") ? { fill: "#000000" } : {})}
            {...(path.getAttribute("stroke") ? { stroke: "#000000" } : {})}
            {...(path.getAttribute("stroke-width") ? { strokeWidth: Number(path.getAttribute("stroke-width")) } : {})}
          />
        );
      })}
    </Group>
  );
};

export default Barcode;