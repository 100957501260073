import { Col, Dropdown, Input, Modal, Row, Table } from "antd";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import CreateButton from "../../components/CreateButton";
import ErrorBanner from "../../components/ErrorBanner";
import { ScreenContext } from "../../contexts/ScreenContext";
import ProductController from "../../controllers/ProductController";
import ImageService from "../../services/ImageService";
import ProductService from "../../services/ProductService";
import RoleCheckService from "../../services/RoleCheckService";
import styles from "./Products.module.css";

const { Search } = Input;

const Products = ({ user, alarm }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("products");
  const productController = useRef(new ProductController(user));
  const isInitialMount = useRef(true);

  const actionListItems = [
    {
      type: "enable",
      description: t("action0"),
    },
    {
      type: "disable",
      description: t("action1"),
    },
    {
      type: "edit",
      description: t("action2"),
    },
    {
      type: "approve",
      description: t("action4"),
    },
    {
      type: "reject",
      description: t("action3"),
    },
    {
      type: "copy",
      description: t("action5"),
    },
  ];

  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const productApprovalRequired = user.account.productApprovalRequired;
  const [showConfirmRejectProduct, setShowConfirmRejectProduct] =
    useState(false);
  const [rejectedProduct, setRejectedProduct] = useState();
  const [locationPathname, setLocationPathname] = useState("");
  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smallScreenQuery = useMediaQuery(smallScreen);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    smallScreenQuery && handleShowSmScreenHeader(true);
  }, [smallScreenQuery]);

  useEffect(() => {
    setLocationPathname(location.pathname);
  }, [location.pathname]);

  useEffect(async () => {
    productController.current = new ProductController(user);
  }, [user]);

  useEffect(async () => {
    if (alarm) {
      await handleSearchOrPeding(alarm.callToActionRef, tableParams.pagination);
    } else if (location.state?.refresh && location.state?.product) {
      await handleSearchOrPeding(location.state.product.packageId, {
        ...tableParams.pagination,
        current: 1,
      });
    }
  }, [
    alarm?.callToActionRef,
    location.state?.refresh,
    location.state?.product,
  ]);

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      await handleSearchOrPeding(filterText, {
        ...tableParams.pagination,
      });
    }
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);

  useEffect(async () => {
    //only runs the first time
    await handleSearchOrPeding(filterText, tableParams.pagination);
  }, []);

  const debouncedSearch = useCallback(
    //500ms before search (for fast typing)
    debounce(
      async (value) =>
        await handleSearchOrPeding(value, {
          current: 1,
          pageSize: tableParams.pagination?.pageSize,
        }),
      500
    ),
    []
  );

  const handlePaginationChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setProducts([]);
    }
  };

  const handleProductsResult = (error, data, pagination) => {
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      setProducts(data.products);
      setTableParams({
        ...tableParams,
        pagination: {
          ...pagination,
          total: data?.count ?? tableParams.pagination.pageSize,
        },
      });
    }
  };

  const handleSearch = async (searchText, pagination) => {
    setLoading(true);
    setFilterText(searchText);
    await productController.current.searchForProductsPaginated(
      searchText,
      pagination.current,
      pagination.pageSize,
      (error, data) => handleProductsResult(error, data, pagination)
    );
  };

  const handlePending = async (pagination) => {
    setLoading(true);
    setFilterText("");
    await productController.current.getPendingProductsPaginated(
      pagination.current,
      pagination.pageSize,
      (error, data) => handleProductsResult(error, data, pagination)
    );
  };

  const handleSearchOrPeding = async (searchText, pagination) => {
    searchText = (searchText ?? "").trim();
    setFilterText(searchText);
    if (searchText || !productApprovalRequired) {
      await handleSearch(searchText, pagination);
    } else {
      await handlePending(pagination);
    }
  };

  const handleCreate = () => {
    if (productApprovalRequired) {
      const pendingProduct = { isPending: true, images: [] };
      navigate("create", { state: { product: pendingProduct, isNew: true } });
    } else {
      const activeProduct = { isPending: false, images: [] };
      navigate("create", { state: { product: activeProduct, isNew: true } });
    }
  };

  const getActionMenu = (product) => {
    let listItems = [...actionListItems];

    if (!RoleCheckService.canCreateProduct(user)) {
      let index = listItems.findIndex((o) => o.type === "edit");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canCreateProduct(user) ||
      (product.isEnabled && !product.isPending) ||
      product.isPending
    ) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canCreateProduct(user) ||
      !product.isEnabled ||
      (product.isEnabled && product.isPending)
    ) {
      let index = listItems.findIndex((o) => o.type === "disable");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.deleteProducts(user) ||
      !product.isPending ||
      !productApprovalRequired
    ) {
      const index = listItems.findIndex((o) => o.type === "approve");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.deleteProducts(user) ||
      !product.isPending ||
      !productApprovalRequired
    ) {
      let index = listItems.findIndex((o) => o.type === "reject");
      listItems.splice(index, 1);
    }
    // if (!RoleCheckService.addEquivalencies(user)) {
    //   let index = listItems.findIndex((o) => o.type === "equivalencies");
    //   listItems.splice(index, 1);
    // }
    return listItems;
  };

  const handleAction = (action, product) => {
    let newProduct = { ...product };
    switch (action.type) {
      case "enable":
        newProduct.isEnabled = true;
        handleEnable(newProduct);
        break;
      case "disable":
        newProduct.isEnabled = false;
        handleEnable(newProduct);
        break;
      case "edit": {
        const tempProduct = { ...product };
        if (productApprovalRequired) {
          tempProduct.isPending = true;
        }
        navigate("create", { state: { product: tempProduct } });
        break;
      }
      case "approve":
        newProduct.isPending = false;
        handleUpdate(newProduct);
        break;
      case "reject":
        setRejectedProduct(newProduct);
        setShowConfirmRejectProduct(true);
        break;
      case "copy":
        navigate("create", { state: { isCopy: true, product: product } });
        break;
      // case "equivalencies":
      //   navigate("equivalencies", { state: { product: product } });
      //   break;
      default:
        break;
    }
  };

  const handlePullAllImagesFromFdb = () => {
    ImageService.pullAllFdbImages(user)
      .then(() => {})
      .catch((error) => {
        setError(error);
      });
  };

  const handleEnable = async (product) => {
    if (product.allowVBM) {
      product.updateVBM = true;
    }
    ProductService.enable(user, product)
      .then((productDoc) => {
        let index = products.findIndex((o) => o._id === productDoc._id);
        let list = [...products];
        list[index] = productDoc;
        setProducts(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleUpdate = (product) => {
    setLoading(true);
    ProductService.update(user, product)
      .then((productDoc) => {
        let index = products.findIndex((o) => o._id === productDoc._id);
        let list = [...products];
        list[index] = productDoc;
        setProducts(list);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (product) => {
    setLoading(true);
    ProductService.delete(user, product)
      .then((productDoc) => {
        if (product.revision === 1) {
          let index = products.findIndex((o) => o._id === productDoc._id);
          let list = [...products];
          list.splice(index, 1);
          setProducts(list);
        } else {
          let index = products.findIndex((o) => o._id === productDoc._id);
          let list = [...products];
          list[index] = productDoc;
          setProducts(list);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRowClassName = (product) => {
    let className = ``;

    if (!product.isEnabled) {
      className += `${styles["Products__listitem--disabled"]}`;
    } else if (product.isPending) {
      className += `${styles["Products__listitem--pending"]}`;
    }

    return className;
  };

  return (
    <>
      {showConfirmRejectProduct && (
        <Modal
          title={t("rejectTitle")}
          open={showConfirmRejectProduct}
          onCancel={() => {
            setRejectedProduct(null);
            setShowConfirmRejectProduct(false);
          }}
          onOk={() => {
            handleDelete(rejectedProduct);
            setRejectedProduct(null);
            setShowConfirmRejectProduct(false);
          }}
        >
          {t("rejectConfirm")}
        </Modal>
      )}

      <Outlet />
      {error && (
        <div className={styles.Products__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}

      {(locationPathname === "/settings/products" ||
        locationPathname === "/inventory/medications") && (
        <Row gutter={5} align={"middle"}>
          {!smallScreenQuery && (
            <Col span={24} style={{ height: "70px" }}></Col>
          )}
          <Col span={smallScreenQuery ? 24 : 12}>
            <Search
              placeholder={
                smallScreenQuery
                  ? t("searchPlaceholderMobile")
                  : t("searchPlaceholder")
              }
              value={filterText}
              allowClear={true}
              onChange={async (e) => {
                const value = e.target.value;
                setFilterText(value);
                debouncedSearch(value);
              }}
            />
          </Col>

          <Col span={smallScreenQuery ? 24 : 12}>
            <Row justify={"end"}>
              <Col span={smallScreenQuery ? 24 : undefined}>
                {RoleCheckService.isSuperAdmin(user) && (
                  <CreateButton
                    onClick={handlePullAllImagesFromFdb}
                    isEnabled={true}
                    labelName={t("pullImagesLabel")}
                  />
                )}
              </Col>

              <Col span={smallScreenQuery ? 24 : undefined}>
                {RoleCheckService.canCreateProduct(user) && (
                  <CreateButton
                    onClick={handleCreate}
                    isEnabled={true}
                    labelName={t("createButtonLabel")}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Table
              loading={loading}
              columns={[
                {
                  title: t("col0"), //Id
                  render: (p) => p.packageId,
                  width: 130,
                  ellipsis: true,
                },
                {
                  title: t("col1"), //Description
                  render: (p) =>
                    `${productController?.current?.getDefaultName(p) ?? ""}`,
                  ellipsis: true,
                },
                {
                  title: t("col6"), //Form
                  render: (p) =>
                    `${p.dosageForm?.type ?? p.dosageForm?.description ?? ""}`,
                  ellipsis: true,
                  responsive: ["xxl", "xl"],
                },
                {
                  title: t("col3"), //Manufacturer
                  render: (p) =>
                    `${p.manufacturer?.fullName || p.manufacturer?.abbrName}`,
                  ellipsis: true,
                },
                {
                  title: t("col5"), //Label ID
                  render: (p) => `${p.humanReadableId}`,
                  ellipsis: true,
                  responsive: ["xxl", "xl"],
                  width: 130,
                },
                {
                  title: t("col4"), //Actions
                  width: 100,
                  align: "center",
                  render: (product) =>
                    RoleCheckService.canCreateProduct(user) ? (
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: getActionMenu(product).map((item) => ({
                            key: item.action,
                            label: (
                              <div onClick={() => handleAction(item, product)}>
                                {item.description}
                              </div>
                            ),
                          })),
                        }}
                      >
                        <div style={{ cursor: "pointer", margin: "4px" }}>
                          <MoreActionsIcon />
                        </div>
                      </Dropdown>
                    ) : null,
                },
              ]}
              rowKey={(record) => record._id}
              dataSource={products}
              pagination={tableParams.pagination}
              onChange={handlePaginationChange}
              scroll={
                !products || products.length === 0
                  ? undefined
                  : { y: window.innerHeight - 320 }
              }
              rowClassName={getRowClassName}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Products;
