import LabelService from "../../services/LabelService";

class LabelController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  getLabels = async () => {
    let results = undefined;
    try {
      results = await LabelService.getLabels(this.user);
    } catch (err) {
      console.log("err: ", err);
    }
    return results;
  };

  getLabel = async (label) => {
    let results = undefined;
    try {
      results = await LabelService.getLabel(this.user, label);
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  createLabel = async (
    name,
    labelType,
    machineType,   
    sheetWidth,
    sheetHeight, 
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    customizedLabel,
    template,
  ) => {
    let results = undefined;
    try {
      results = await LabelService.createLabel(
        this.user,
        this.user.token,
        name,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight, 
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        customizedLabel,
        template,
      );
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  updateLabel = async (
    label,
    name,
    labelType,
    machineType,
    sheetWidth,
    sheetHeight, 
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    customizedLabel,
    template,
  ) => {
    let results = undefined;
    try {
      results = await LabelService.updateLabel(
        this.user,
        this.user.token,
        label,
        name,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight, 
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        customizedLabel,
        template,
      );
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  deleteLabel = async (label) => {
    let results = undefined;
    try {
      results = await LabelService.deleteLabel(this.user, label);
    } catch (err) {
      console.log(err);
    }
    return results;
  };
}

export default LabelController;
