import React from 'react';
import styles from './Properties.module.css';
import { useTranslation } from 'react-i18next';


const StageProperties = ({ 
  width,
  setWidth,
  height,
  setHeight,
}) => {
  const { t } = useTranslation("labelEditor");

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("widthLabel")} (mm):</label>
          <input
            key={`stage-width`}
            name="width"
            type="number"
            value={width || 100}
            onChange={(e) => setWidth(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("heightLabel")} (mm):</label>
          <input
            key={`stage-height`}
            name="height"
            type="number"
            value={height || 100}
            onChange={(e) => setHeight(Number(e.target.value))}
            className={styles.Properties__input}
          />
        </div>     
      </div>
       
    </div>
  );
};

export default StageProperties;
