import React, {Fragment, useState} from "react";
import { useTranslation } from "react-i18next";
import Spacer from "../../../components/Spacer";
import ErrorBanner from "../../../components/ErrorBanner";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import styles from "./DispensingOrdersDesktopView.module.css";
import SearchBar from "../../../components/SearchBar";

const DispensingOrdersDesktopView = ({
  sites,
  error,
  onError,
  getSiteHeader,
  dialogRef,
  getPendingColumn,
  getRoutedColumn,
  getPackagedColumn,
  getPackagingMethodWidget,
  getDeviceCoverageWidget,
  productFieldControllerRef,
  pendingDOSelection,
  orderDetailsDialog,
  onOrderDetailsDialog,
  showDeviceRouteSelector,
  showFacilitySelector,
  getFacilitySelector,
  getDeviceRouteSelector,
  showGetDeviceModal,
  selectDeviceComponent,
  showPicklistModal,
  getPickList,
  showPrintMedListModal,
  getPrintMedList,
}) => {
  const { t } = useTranslation("dispensingOrders");
  const [filterText, setFilterText] = useState('');

  // PAGE RENDERING
  if (showDeviceRouteSelector) {
    return getDeviceRouteSelector();
  } else if (showGetDeviceModal) {
    return selectDeviceComponent();
  } else if (showPicklistModal) {
    return getPickList();
  } else if (showPrintMedListModal) {
    return getPrintMedList();
  } else if(showFacilitySelector){
    return getFacilitySelector();
  }

  return (
    <Fragment>
      <div className={styles.view}>
        <div className={styles.header} style={{alignContent: 'center'}}>
          <h1 className={styles.title}>{t("title")}</h1>
          <SearchBar onSearch={(text)=>{
            setFilterText(text);
          }} onEnter={()=>{}} placeholder={t("searchPlaceholder")} />
        </div>


        <main id={"main"} className={styles.main}>
          <div className={styles.errorBannerContainer}>
            {error && (
              <ErrorBanner message={error} onClose={() => onError("")} />
            )}
          </div>
          <br />
          <br />
          <div className={styles.sites}>
            {sites.map((site, index) => {
              return getSiteHeader(site, index);
            })}
          </div>
          {getPackagingMethodWidget()}
          <Spacer space={30} unit={"px"} />
          {pendingDOSelection.length > 0 && getDeviceCoverageWidget()}
          <Spacer space={30} unit={"px"} />
          <div className={styles.siteDispensingOrderDetails}>
            {getPendingColumn(filterText)}
            {getRoutedColumn(filterText)}
            {getPackagedColumn(filterText)}
          </div>
          <div className={styles.bottomSpacer}>
            <DialogComponent
              width="400px"
              target={"#main"}
              isModal={true}
              position={{ X: "center", Y: "center" }}
              visible={orderDetailsDialog}
              ref={(dialog) => {
                dialogRef.current = dialog;
              }}
              buttons={[
                {
                  click: () => {
                    onOrderDetailsDialog(null);
                    dialogRef.current.hide();
                  },
                  buttonModel: {
                    isPrimary: true,
                    content: t("closeButtonLabel"),
                    cssClass: "custom-dialog-button",
                  },
                },
              ]}
              close={() => {
                onOrderDetailsDialog(null);
              }}
            >
              <div>
                <div className="dialog-content">
                  <br />
                  <div className="dialogText">
                    <b>{orderDetailsDialog?.patientInfo.name}</b>
                  </div>
                  <br />
                  <div className="dialogText">
                    {productFieldControllerRef.current.getDefaultName(
                      orderDetailsDialog?.product
                    )}
                  </div>
                  <br />
                  <div className="dialogText">
                    {t("qtyLabel")}: {orderDetailsDialog?.qtyNeeded}
                  </div>
                </div>
              </div>
            </DialogComponent>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
export default DispensingOrdersDesktopView;
