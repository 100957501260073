import React, { Fragment, useRef } from "react";
import { Group as KonvaGroup, Rect as KonvaRect, Path } from "react-konva";
import { pxToMm, mmToPx } from "../Converters";
import Barcode from "./Barcode";
import Image from "./Image";
import Text from "./Text";
import Rect from "./Rect";

const GridLayout = ({
  obj,
  updateObject,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  viewOnly,
}) => {
  const dragProps =
    onDragStart && onDragMove && onDragEnd
      ? {
          onDragStart,
          onDragMove,
          onDragEnd,
        }
      : {};

  const transformProps = onTransformEnd
    ? {
        onTransformEnd,
      }
    : {};

  const components = {
    rect: Rect,
    text: Text,
    image: Image,
    barcode: Barcode,
  };

  return (
    <KonvaGroup
      objectType={"gridLayout"}
      key={obj.id}
      id={obj.id}
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}
      objects={obj.objects}
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
    >
      {obj.objects.map((row, y) => {
        console.log("row", row)
        return row.map((innerObj, x) => {
          const Component = components[innerObj?.type] || null;
          const cellWidth = mmToPx(obj.width / obj.cols)
          const cellHeight = mmToPx(obj.height / obj.rows)

          return (
            <Fragment key={`${obj.id}-${y}-${x}`}>
              <KonvaRect
                objectType={"gridLayout"}
                key={`rect${obj.id}-${y}-${x}`}
                width={cellWidth}
                height={cellHeight}
                x={cellWidth * x}
                y={cellHeight * y}
                row={y}
                col={x}
                fill={null}
                stroke={viewOnly ? null : "black"}
                strokeWidth={1}
                dash={[10, 10]}
              />
              {Component && (
                <Component
                  obj={innerObj}
                  key={innerObj.id}
                  updateObject={updateObject}
                  onDragStart={onDragStart}
                  onDragMove={onDragMove}
                  onDragEnd={onDragEnd}
                  onTransformEnd={onTransformEnd}
                />
              )}
            </Fragment>
          );
        })
      })}
    </KonvaGroup>
  );
};

export default GridLayout;
