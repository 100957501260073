import React, { useState, useRef, useEffect } from "react";
import Konva from "konva";
import { Stage, Layer, Rect as KonvaRect, Transformer } from "react-konva";
import { pxToMm, mmToPx } from "./Converters";
import "../../fonts/fontFaces.css";
import Barcode from "./StageComponents/Barcode";
import Image from "./StageComponents/Image";
import Text from "./StageComponents/Text";
import Rect from "./StageComponents/Rect";
import Group from "./StageComponents/Group";
import GridLayout from "./StageComponents/GridLayout";


Konva.pixelRatio = 4;

const LabelEditorStage = ({ 
  stageRef,
  width, 
  height, 
  zoom,
  objects, 
  setObjects,
  selectedId,
  setSelectedId, 
  selectObject,
  updateObject,
  dropObject,
  transformerLock,
}) => {
  const trRef = useRef();
  const gridCell = useRef(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);

  const components = {
    "rect": Rect,
    "text": Text,
    "image": Image,
    "barcode": Barcode,
    "group": Group,
    "gridLayout": GridLayout,
  };

  useEffect(() => {
    setSelectedObject(selectObject(selectedId))
  }, [selectedId])
    
  
  useEffect(() => {
    const loadFonts = async () => {
      try {
        await document.fonts.ready;
        setFontsLoaded(true);
      } catch (error) {
        console.error("Font loading failed:", error);
      }
    };

    loadFonts();
  }, []);

  useEffect(() => {
    if (selectedId && trRef.current) {
      const selectedNode = stageRef.current.findOne("#" + selectedId);
      trRef.current.nodes([selectedNode]);
      trRef.current.getLayer().batchDraw();      

      if (transformerLock) {
        trRef.current.resizeEnabled(false);
        trRef.current.rotateEnabled(false);
      } else {        
        trRef.current.resizeEnabled(true);
        trRef.current.rotateEnabled(true);
        if (selectedNode.className === "Text") {
          trRef.current.enabledAnchors(["middle-left", "middle-right"])
        } else {
          trRef.current.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'])
        }
      }     
    } else if (trRef.current) {
      trRef.current.nodes([]);
    }
  }, [selectedId, stageRef, transformerLock, selectedObject]);

  const handleStageClick = (e) => {
    const selectObjectId = (object) => {
      if (["barcode", "group", "gridLayout"].includes(object.attrs.objectType)) {
        return object.parent.id();
      } else {
        return object.id()
      }      
    }

    if (e.target === e.target.getStage()) {
      setSelectedId(null);
    } else {
      const position = stageRef.current.getRelativePointerPosition()
      position.x = position.x * (zoom / 100)
      position.y = position.y * (zoom / 100)

      const clickedObjects = stageRef.current.getAllIntersections(
        position
      );
  
      if (clickedObjects.length > 1) {
        const smallestObject = clickedObjects.reduce((smallest, current) => {
          const currentArea = current.width() * current.height();
          const smallestArea = smallest.width() * smallest.height();
          return currentArea < smallestArea ? current : smallest;
        });
  
        setSelectedId(selectObjectId(smallestObject));
      } else if (clickedObjects.length === 1) {
        setSelectedId(selectObjectId(clickedObjects[0]));
      }
    }
  };
  

  const intersectId = (id) => {
    const intersections = stageRef.current.getAllIntersections(
      stageRef.current.getPointerPosition()
    );
    const activeObject = intersections.find(obj => {
      const objId = obj.id() || obj?.parent.id()
      return objId === id    
    });

    if (!activeObject) {
      return null;
    } else if (activeObject.id()) {
      return activeObject;    
    } else {
      return activeObject.parent;
    }
  }

  function hasAncestor(node, ancestorToFind) {
    let parent = node.getParent();
    while (parent) {
      if (parent === ancestorToFind) {
        return true;
      }
      parent = parent.getParent();
    }
    return false;
  }

  const handleDragStart = (e) => {
    const draggedObject = e.target;
    const activeObject = intersectId(selectedId);
  
    if (activeObject && !activeObject.attrs.locked) {
      if (draggedObject !== activeObject || hasAncestor(draggedObject, activeObject)) {
        e.target.stopDrag();
        activeObject.startDrag(e);
      }
    } else {
      e.target.stopDrag();
    }
  };

  const handleDragMove = (e) => {  
    console.log("move")
    if (e.target.attrs.objectType === "gridLayout") return;
    console.log("move not grid")

    const intersections = stageRef.current.getAllIntersections(
      stageRef.current.getPointerPosition()
    );

    const hoveringGridCell = intersections.find(node => node.attrs.objectType === "gridLayout");
    if (hoveringGridCell) {
      hoveringGridCell.fill("blue")
      if (!gridCell.current) {
        gridCell.current = hoveringGridCell
      }
    }

    if (gridCell.current && gridCell.current !== hoveringGridCell) {
      gridCell.current.fill(null)
      gridCell.current = hoveringGridCell;
    }
  }

  const handleDragEnd = (e) => {
    const id = e.target.id();
    const obj = selectObject(id);
    if (obj && !gridCell.current) {
      updateObject({
        ...obj,
        x: pxToMm(e.target.x()),
        y: pxToMm(e.target.y()),
      });
    } else if (obj) {
      const row = gridCell.current.attrs.row;
      const col = gridCell.current.attrs.col;
      const gridNode = gridCell.current.parent
      const gridObject = selectObject(gridNode.id())
      const gridObjects = [...gridObject.objects]

      gridObjects[row][col] = {
        ...obj,
        x: pxToMm(gridCell.current.x()),
        y: pxToMm(gridCell.current.y()),
      };
      
      dropObject(obj);

      updateObject({
        ...gridObject,
        objects: gridObjects,        
      }); 

      gridCell.current.fill(null)
      gridCell.current = null
    }
  };


  const handleTransformEnd = (e) => {
    const id = e.target.id();
    const obj = selectObject(id);
    const node = e.target;

    const updatedObjects =
      obj.type === "group"
        ? {
            objects: obj.objects.map((innerObj) => ({
              ...innerObj,
              x: Math.round(innerObj.x * node.scaleX() * 10) / 10,
              y: Math.round(innerObj.y * node.scaleY() * 10) / 10,
              width: Math.round(innerObj.width * node.scaleX() * 10) / 10,
              height: Math.round(innerObj.height * node.scaleY() * 10) / 10,
              rotation: node.getAbsoluteRotation(),
            })),
          }
        : obj.type === "gridLayout"
        ? {
            objects: obj.objects.map((row) =>
              row.map((innerObj) => {
                if (!innerObj) {
                  return innerObj;
                } else {
                  return {
                    ...innerObj,
                    x: Math.round(innerObj.x * node.scaleX() * 10) / 10,
                    y: Math.round(innerObj.y * node.scaleY() * 10) / 10,
                    width: Math.round(innerObj.width * node.scaleX() * 10) / 10,
                    height:
                      Math.round(innerObj.height * node.scaleY() * 10) / 10,
                    rotation: node.getAbsoluteRotation()
                  };
                }
              })
            ),
          }
        : {};

    updateObject({
      ...obj,
      x: pxToMm(node.x()),
      y: pxToMm(node.y()),
      width: pxToMm(node.width() * node.scaleX()),
      height: pxToMm(node.height() * node.scaleY()),
      rotation: node.getAbsoluteRotation(),
      ...updatedObjects,      
    });
    node.scaleX(1);
    node.scaleY(1);
  }

  if (!fontsLoaded) {
    return <div></div>;
  }

  return (
    <Stage 
      ref={stageRef}
      key={"stage"}
      container={"stage"}
      width={mmToPx(width) * zoom / 100} 
      height={mmToPx(height) * zoom / 100}        
      onClick={handleStageClick}
      scaleX={zoom / 100}
      scaleY={zoom / 100}
    >
      <Layer key={"layer"}>
        <KonvaRect
          id={"background"} 
          key={"background"} 
          x={0}
          y={0}
          width={mmToPx(width)} 
          height={mmToPx(height)} 
          fill={"white"}  
          listening={false}
        />

        {objects.toReversed().map((obj) => {
          const Component = components[obj.type]

          if (!Component) return null;

          return (
            <Component
              obj={obj}     
              key={obj.id}          
              updateObject={updateObject}
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
              onTransformEnd={handleTransformEnd}
            />
          )
        })}

        <Transformer ref={trRef} ignoreStroke={true} rotationSnaps={Array.from({ length: 360 / 15 }, (_, i) => i * 15)} />
      </Layer>
    </Stage>
  );
};

export default LabelEditorStage;
