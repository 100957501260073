import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
  memo,
} from "react";
import { NavbarContext } from "../../contexts/NavbarContext";
//import BarcodeComponent from "./LabelComponents/BarcodeComponent";
//import Rectangle from "./LabelComponents/Rectangle";
//import Text from "./LabelComponents/Text";
//import Repeater from "./LabelComponents/Repeater";
//import Image from "./LabelComponents/Image";
import { placeHolderImage } from "./LabelComponents/placeHolderImage.js";
//import RectangleProperties from "./PropertyComponents/RectangleProperties/RectangleProperties";
//import BarcodeProperties from "./PropertyComponents/BarcodeProperties/BarcodeProperties";
//import TextProperties from "./PropertyComponents/TextProperties/TextProperties";
//import RepeaterProperties from "./PropertyComponents/RepeaterProperties/RepeaterProperties";
//import ImageProperties from "./PropertyComponents/ImageProperties/ImageProperties";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import ModalBackdrop from "../../components/Modal";
import { ReactComponent as BarcodeSVG } from "./svgs/barcode2.svg";
import { ReactComponent as ImageSVG } from "./svgs/image.svg";
import { ReactComponent as RectSVG } from "./svgs/rectangle2.svg";
import { ReactComponent as RepeaterSVG } from "./svgs/repeater.svg";
import { ReactComponent as TextSVG } from "./svgs/text.svg";
import { ReactComponent as GroupSVG } from "../../assets/group-items.svg";
import { ReactComponent as GridLayoutSVG } from "../../assets/grid-layout.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash-icon.svg"
import CindyCanisterLabelTemplate from "./labelTemplates/CindyCanisterLabelTemplate";
import styles from "./LabelEditor.module.css";
import { useTranslation } from "react-i18next";
import { L10n } from "@syncfusion/ej2-base";
import LabelEditorStage from "./LabelEditorStage.js";
import StageProperties from "./PropertyComponents/StageProperties.js";
import GroupProperties from "./PropertyComponents/GroupProperties.js";
import GridLayoutProperties from "./PropertyComponents/GridLayoutProperties.js";
import RectProperties from "./PropertyComponents/RectProperties.js";
import TextProperties from "./PropertyComponents/TextProperties.js";
import ImageProperties from "./PropertyComponents/ImageProperties.js";
import BarcodeProperties from "./PropertyComponents/BarcodeProperties.js";
import { mmToPx, pxToMm } from "./Converters.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LabelViewerStage from "./LabelViewerStage.js";
import PrintProperties from "./PropertyComponents/PrintProperties.js";
import AreaProperties from "./PropertyComponents/AreaProperties.js";
import { ReactComponent as Locked } from "../../assets/lock-closed.svg";
import { ReactComponent as Unlocked } from "../../assets/lock-open.svg";



const LabelEditor = ({
  user,
  label,
  onResetCreateLabelStatesToDefaults,
  labelName,
  labelType,
  machineType,
  customizedLabel,
  onHideLabelCanvas,
  _controllerRef,
  nodesArrayForUpdate,
  handleNodesArrayForUpdate,
  labelModificationInProgress,
  onLabelModificationInProgress,
}) => {
  const { t, i18n } = useTranslation("labelEditor")
  const localization = {
    [i18n.language]: {
      colorpicker: {
        Apply: t('applyButtonLabel'),
        Cancel: t('cancelButtonLabel'),
        ModeSwitcher: t('switcherButtonLabel')
      }
    }
  };
  L10n.load(localization);
  
  const stageRef = useRef(null);
  const retrofitted = useRef(false);
  const [objects, setObjects] = useState([]);
  const [selectedId, setSelectedId] = useState(null);  
  const [selectedLock, setSelectedLock] = useState(false);

  const [viewerMode, setViewerMode] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [sheetWidth, setSheetWidth] = useState(594);
  const [sheetHeight, setSheetHeight] = useState(841);
  const [labelWidth, setLabelWidth] = useState(100);
  const [labelHeight, setLabelHeight] = useState(100);
  const [rowCount, setRowCount] = useState(1);
  const [colCount, setColCount] = useState(1);
  
  const [areaWidth, setAreaWidth] = useState(null);
  const [areaHeight, setAreaHeight] = useState(null);

  
  useEffect(() => {
    const calculateArea = () => {
      const availableWidth = window.innerWidth - 800; // navbar 120, home padding 60*2, panels 260 * 2, buffer 40        
      const availableHeight = window.innerHeight - 305; // home padding 40+60, buttons 48+34, title 50+33, buffer 40      
      
      setAreaWidth(availableWidth);
      setAreaHeight(availableHeight);
    };

    calculateArea();
    window.addEventListener('resize', calculateArea);

    return () => window.removeEventListener('resize', calculateArea);
  }, [])

  useEffect(() => {
    if (label) {      
      console.log("label", label)
      setSheetWidth(label?.sheetWidth || 594);
      setSheetHeight(label?.sheetHeight || 841);
      setLabelWidth(label.labelWidth);
      setLabelHeight(label.labelHeight);
      setRowCount(label.rowCount || 1);
      setColCount(label.colCount || 1);
    }
  }, [label])


  useEffect(() => {
    console.log("objects", objects)
  }, [objects])

  
  const calculateGroupBoundingBox = (group) => {
    if (group.objects.length === 0) return { width: 10, height: 10 };
  
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  
    group.objects.forEach(obj => {
      minX = Math.min(minX, obj.x + group.x);
      minY = Math.min(minY, obj.y + group.y);
      maxX = Math.max(maxX, obj.x + group.x + obj.width);
      maxY = Math.max(maxY, obj.y + group.y + obj.height);
    });
  
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY
    };
  };


  const selectObject = (id) => {
    if (!id) return null;

    for (const obj of objects) {
      if (obj.id === id) {
        return obj;
      } else if (obj?.objects) {
          const innerObj = obj.objects.flat().find(innerObj => innerObj?.id === id);
          if (innerObj) {
            return innerObj;
          }
      }
    }
    return null;
  }
  
  const dropObject = (droppedObject) => {
    setObjects((prevObjects) => {
      return prevObjects.filter(obj => obj.id !== droppedObject.id)
    })
  }
  
  /**
  const dropObject = (droppedObject) => {
    setObjects((prevObjects) => {
      return prevObjects
        .filter((obj) => obj.id !== droppedObject.id)
        .map((obj) => {
          if (obj.objects && Array.isArray(obj.objects[0])) {
            return {
              ...obj,
              objects: obj.objects.map((nestedArr) => {
                return nestedArr.map((nestedObj) => {
                  if (nestedObj && nestedObj.id === droppedObject.id) {
                    return null;
                  }
                  return nestedObj;
                });
              }),
            };
          } else if (obj.objects) {
            return {
              ...obj,
              objects: obj.objects.filter((nestedObj) => {
                return nestedObj.id !== droppedObject.id;
              }),
            };
          }
          return obj;
        });
    });
  };
   */
  
  

  const updateObject = (updatedObject, dropId) => {
    setObjects((prevObjects) => {
      const updatedObjects = prevObjects.map((obj) => {
        if (obj.id === updatedObject.id) {
          return updatedObject;

        } else if (obj.type === "group" && obj.objects.some(o => o.id === updatedObject.id)) {
          const updatedGroup = {
            ...obj,
            objects: obj.objects.map((innerObj) =>
              innerObj.id === updatedObject.id ? updatedObject : innerObj
            ),
          };

          const boundingBox = calculateGroupBoundingBox(updatedGroup);
          const offsetX = boundingBox.x - updatedGroup.x;
          const offsetY = boundingBox.y - updatedGroup.y;
  
          return {
            ...updatedGroup,
            x: boundingBox.x,
            y: boundingBox.y,
            width: boundingBox.width,
            height: boundingBox.height,
            objects: updatedGroup.objects.map((innerObj) => ({
              ...innerObj,
              x: innerObj.x - offsetX,
              y: innerObj.y - offsetY
            })),
          };
        } else {
          return obj;
        }
      });
      console.log("Group",  updatedObjects)
      return updatedObjects
    });
  };
  

  const getNewId = (objects) => {
    const allIds = objects.flatMap((obj) => [
      Number(obj.id),
      ...(obj.objects
        ? obj.objects
          .flat()
          .filter(innerObj => innerObj && innerObj.id)
          .map(innerObj => Number(innerObj.id))
        : []),
    ]);
  
    return allIds.length === 0 
      ? "1"
      : `${Math.max(...allIds) + 1}`;
  };  


  const addRect = (x = 0, y = 0) => {
    const id = getNewId(objects);
    const newObject = {
      type: "rect",
      id: id,
      x: pxToMm(x),
      y: pxToMm(y),
      width: 50,
      height: 50,
      rotation: 0,
      stroke: "#000000",
      strokeWidth: 0.2,
      fill: "white",
      locked: false,
    };
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };
  
  const addText = (x = 0, y = 0) => {
    const id = getNewId(objects);
    const newObject = {
      type: "text",
      id: id,
      x: pxToMm(x),
      y: pxToMm(y),
      width: 20,
      height: pxToMm(12),
      rotation: 0,
      fontSize: 12,
      fontFamily: "Poppins",
      fontStyle: "normal",
      textDecoration: "",
      wrap: "none",
      wrapLines: 1,
      align: "left",
      stroke: "none",
      fill: "#000000",
      defaultValue: "default",
      mappingName: "Default Value",
      mappingPath: "",
      locked: false,
    };
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };

  const addImage = (x = 0, y = 0) => {
    const id = getNewId(objects);
    const newObject = {
      type: "image",
      name: t("productImageLabel"),
      id: id,
      x: pxToMm(x),
      y: pxToMm(y),
      rotation: 0,
      mimeType: "image/png",
      base64EncodedString: placeHolderImage,
      getImagesFromDatabase: true,
      locked: false,
    };    
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };
  

  const addBarcode = (x = 0, y = 0) => {
    const id = getNewId(objects);
    const newObject = {
      type: "barcode",
      id: id,
      x: pxToMm(x),
      y: pxToMm(y),
      rotation: 0,

      symbology: "code128",
      defaultValues: {
        code128: "Bag 001",
        code39: "BAG 001",
        code39ext: "Bag 001",
        qrcode: "123~Happy Pill~ghtrs",
        datamatrix: "BAG 001",
      },

      propertyName: "bagBarcode",
      showValue: true,
      mappingName: "Default Value",
      mappingPath: "",
      locked: false,
    };
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };

  const addGroup = () => {
    const id = getNewId(objects);
    const newObject = {
      type: "group",
      id: id,
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      rotation: 0,
      stroke: "black",
      strokeWidth: 0.2,
      fill: null,
      locked: false,
      objects: [],
    };
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };

  const addGridLayout = () => {
    const id = getNewId(objects);
    const newObject = {
      type: "gridLayout",
      name: `${t("gridLayoutLabel")} (2x2)`,
      id: id,
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      rotation: 0,
      rows: 2,
      cols: 2,
      stroke: "black",
      strokeWidth: 0.2,
      fill: null,
      locked: false,
      objects: [[null, null], [null, null]],
    };
    setObjects([newObject, ...objects]);
    setSelectedId(id);
  };
  
  
  useEffect(() => {
    console.log("Selected", selectObject(selectedId))
  }, [selectedId])

  useEffect(() => {
    if (selectedId) {
      const selectedObject = selectObject(selectedId)  
      setSelectedLock(!!selectedObject?.locked)
      console.log("Set lock", !!selectedObject?.locked)
    }
  }, [selectedId, objects])


  const [error, setError] = useState("");
  const [barcodeValueError, setBarcodeValueError] = useState(""); // 1

  const [newNode, setNewNode] = useState(null); // 3

  const [nodes, setNodes] = useState([]); // 10
  const nodesRef = useRef(null); // 11
  nodesRef.current = nodes;

  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState(false); //21

  const [showModal, setShowModal] = useState(false); //22


  const {
    labelModificationInProgressViaContext,
    handleLabelModificationInProgressViaContext,
  } = useContext(NavbarContext);



  const handleSaveLabel = () => {
    _controllerRef.current
      .createLabel(
        labelName,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        customizedLabel,
        objects,
      )
      .then((result) => {
        if (result) {
          // after processing, reset label state and return to "label name" page
          //resetStatesToDefault();
          setNodes([]);
          setNewNode(null);
          //setDragPosition(null);
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
    handleLabelModificationInProgressViaContext(false);
  };

  const handleUpdateLabel = () => {
    _controllerRef.current
      .updateLabel(
        label,
        labelName,
        labelType,
        machineType,        
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        customizedLabel,
        objects,
      )
      .then((result) => {
        if (result) {
          // after processing, reset label state and return to "label name" page
          //resetStatesToDefault();
          setNodes([]);
          setNewNode(null);
          //setDragPosition(null);
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
    handleLabelModificationInProgressViaContext(false);
  };

  const retrofitPosition = (node, parent, positions) => {
    node.xPosmm = Number(node.xPosmm) + positions[parent.nodeId].x
    node.yPosmm = Number(node.yPosmm) + positions[parent.nodeId].y

    if (parent.parent) {
      retrofitPosition(node, parent.parent, positions)
    }
  }

  const retrofitNodes = (nodes) => {
    console.log("Received:", nodes)

    const rootNode = nodes.find((node) => node.nodeId === 0);
    setLabelWidth(Number(rootNode.widthmm));
    setLabelHeight(Number(rootNode.heightmm));

    const updateKeys = {
      nodeId: "id",
      xPosmm: "x",
      yPosmm: "y",
      heightmm: "height",
      widthmm: "width",
      borderColor: "stroke",
      borderOn: "strokeWidth",
      font: "fontFamily",
      textAlignment: "align",
      textColor: "fill",
    };

    const updateValues = {
      type: {
        childRectangle: "rect",
        rectangle: "rect",
      },
      strokeWidth: {
        true: 0.2,
      },
      symbology: {
        Code128A: "code128",
        Code128B: "code128",
        Code39: "code39",
        DataMatrix: "datamatrix",
        QRBarcode: "qrcode",
      }
    };

    const dropKeys = ["childNodes", "parent", "bold", "italic", "wordWrap", "value", "mapping"];
    const textualValues = ["id"]
    const numericValues = ["x", "y", "height", "width", "strokeWidth", "fontSize"]

    const positions = nodes.reduce((acc, node) => {
      acc[node.nodeId] = {
        x: Number(node.xPosmm) + Number(node?.padding?.left || 0),
        y: Number(node.yPosmm) + Number(node?.padding?.top || 0),
      };
      return acc;
    }, {});

    const updatedNodes = nodes
      .filter(
        (node) =>
          node.nodeId !== 0 &&
          node.type !== "repeater" && // TODO Preset print option?
          !(node.type === "childRectangle" && node.borderOn === false) // TODO convert children to groups
      )
      .map((node) => {
        const updatedNode = {};

        retrofitPosition(node, node.parent, positions);

        for (const key in node) {
          if (key in updateKeys) {
            updatedNode[updateKeys[key]] = node[key];
          } else if (!dropKeys.includes(key)) {
            updatedNode[key] = node[key];
          }
        }

        for (const key in updateValues) {
          if (key in updatedNode) {
            const currentValue = updatedNode[key];
            const newValue = updateValues[key][currentValue];
            if (newValue !== undefined) {
              updatedNode[key] = newValue;
            }
          }
        }
        
        for (const key of textualValues) {
          if (key in updatedNode) {
            updatedNode[key] = updatedNode[key].toString();
          }
        }
        
        for (const key of numericValues) {
          if (key in updatedNode) {
            updatedNode[key] = Number(updatedNode[key]);
          }
        }

        if (updatedNode.type === "text") {
          if (node.bold && node.italic) {
            updatedNode.fontStyle = "bold italic"
          } else if (node.bold) {
            updatedNode.fontStyle = "bold"
          } else if (node.italic) {
            updatedNode.fontStyle = "italic"
          } else {
            updatedNode.fontStyle = "normal"
          }

          if (node.wordWrap) {
            updatedNode.wrap = "word"
          } else {
            updatedNode.wrap = "none"
          }

          updatedNode.mappingName = node.mapping.friendlyName;
          updatedNode.mappingPath = node.mapping.path;
        }
        else if (updatedNode.type === "image") {
          updatedNode.width = pxToMm(Number(node.width))
          updatedNode.height = pxToMm(Number(node.height))
        }
        
        else if (updatedNode.type === "barcode") {
          console.log("Barcode", updatedNode)  
          updatedNode.defaultValues = {
            "code128": node.defaultValueCode128B,
            "code39": node.defaultValueCode39,
            "datamatrix": node.defaultValueDataMatrix,
            "qrcode": node.defaultValueQRBarcode,
          }

          updatedNode.mappingName = node.mapping.friendlyName;
          updatedNode.mappingPath = node.mapping.path;
        }

        updatedNode.locked = false;
        return updatedNode;
      });

    console.log("Retrofitted:", updatedNodes)
    retrofitted.current = true;
    return updatedNodes;
  };

  useEffect(() => {
    if (nodesArrayForUpdate?.length) {
      if (nodesArrayForUpdate[0].hasOwnProperty("xPosmm") && !retrofitted.current) {
        setObjects(retrofitNodes(nodesArrayForUpdate));
      } else {
        setObjects(nodesArrayForUpdate);
      }
      //setNodes(nodesArrayForUpdate);
    }
  }, [nodesArrayForUpdate]);


  const Layers = () => {
    function withDisplayNames(arr) {
      const counts = {
        rect: {},
        image: {},
        text: {},
        barcode: {},
        group: {},
        gridLayout: {},
      };

      const types = {
        rect: t("rectangleLabel"),
        image: t("imageLabel"),
        text: t("textLabel"),
        barcode: t("barcodeLabel"),
        group: t("groupLabel"),
        gridLayout: t("gridLayoutLabel"),
      };

      const idIndex = arr.map((obj) => obj.id);

      const namedObjects = [...arr]
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((item) => {
          let name = item?.mappingName || item?.name || types[item.type];

          if (counts[item.type][name]) {
            counts[item.type][name] += 1;
            name = `${name} (${counts[item.type][name]})`;
          } else {
            counts[item.type][name] = 1;
          }

          return {
            ...item,
            displayName: name,
          };
        });

      return idIndex.map((id) => namedObjects.find((obj) => id === obj.id));
    }


    const onDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(objects);

      let reorderedItem;
      if (result.source.droppableId === "list") {
        [reorderedItem] = items.splice(result.source.index, 1);
      } else {
        const groupId = result.source.droppableId;
        const groupIndex = items.findIndex((item) => item.id === groupId);
        const group = items[groupIndex];
        [reorderedItem] = group.objects.splice(result.source.index, 1);
        reorderedItem.x += group.x;
        reorderedItem.y += group.y;
        
        const bbox = calculateGroupBoundingBox(group)
        const offsetX = bbox.x - group.x;
        const offsetY = bbox.y - group.y;

        items[groupIndex] = {
          ...group,
          x: bbox.x,
          y: bbox.y,
          width: bbox.width,
          height: bbox.height,
          objects: group.objects.map(obj => ({
            ...obj,
            x: obj.x - offsetX,
            y: obj.y - offsetY,
          }))
        };    
      }

      if (result.destination.droppableId === "list") {
        items.splice(result.destination.index, 0, reorderedItem);
      } else {
        const groupId = result.destination.droppableId;
        const groupIndex = items.findIndex((item) => item.id === groupId);
        const group = items[groupIndex];
        reorderedItem.x -= group.x;
        reorderedItem.y -= group.y;
        group.objects.push(reorderedItem);
        const bbox = calculateGroupBoundingBox(group)
        const offsetX = bbox.x - group.x;
        const offsetY = bbox.y - group.y;

        items[groupIndex] = {
          ...group,
          x: bbox.x,
          y: bbox.y,
          width: bbox.width,
          height: bbox.height,
          objects: group.objects.map(obj => ({
            ...obj,
            x: obj.x - offsetX,
            y: obj.y - offsetY,
          }))
        };
      }

      setObjects(items);
    };

    const getIcon = (id, type) => {
      let IconComponent;

      switch (type) {
        case "barcode":
          IconComponent = BarcodeSVG;
          break;
        case "image":
          IconComponent = ImageSVG;
          break;
        case "rect":
          IconComponent = RectSVG;
          break;
        case "repeater":
          IconComponent = RepeaterSVG;
          break;
        case "text":
          IconComponent = TextSVG;
          break;
        case "group":
          IconComponent = GroupSVG;
          break;
        case "gridLayout":
          IconComponent = GridLayoutSVG;
          break;
        default:
          IconComponent = null;
      }

      return (
        <div
          id={`icon-${id}`}
          className={`${styles.LabelEditor__svgContainer} ${
            type === "text"
              ? styles.LabelEditor__objectExplorerTextSVGContainer
              : type === "image"
              ? styles.LabelEditor__objectExplorerImageSVGContainer
              : styles.LabelEditor__objectExplorerSVGContainer
          }`}
        >
          {IconComponent && <IconComponent />}
        </div>
      );
    };

    const LockToggle = memo(({ object }) => (
      <div
        className={styles.LabelEditor__lockContainer}
        onClick={(e) => {
          e.stopPropagation();   
          e.preventDefault();  
          updateObject({
            ...object,
            locked: !object.locked,
          });
        }}
      >
        {object?.locked ? (
          <Locked fill={"var(--rhumbaOrange)"} />
        ) : (
          <Unlocked fill={"var(--green)"} />
        )}
      </div>
    ));

    const renderObject = (obj, index, isNested = false) => (
      <Draggable key={obj.id} draggableId={obj.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`${styles.LabelEditor__layer} ${
              selectedId === obj.id ? styles.LabelEditor__selectedLayer : ""
            } ${isNested ? styles.LabelEditor__nestedLayer : ""}`}
            onClick={(e) => {               
              e.stopPropagation();  
              e.preventDefault();
              setSelectedId(obj.id)            
            }}
          >
            {getIcon(obj.id, obj.type)}
            <div className={styles.LabelEditor__layerName}>{obj.displayName}</div>
            <div className={styles.LabelEditor__layerOptions}>
              <LockToggle object={obj} />            
            </div>
          </div>
        )}
      </Draggable>
    );

    const renderGroup = (group, index) => (
      <Fragment key={group.id}>
        {renderObject(group, index, true)}
        <Droppable droppableId={group.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${styles.LabelEditor__group} ${
                snapshot.isDraggingOver ? styles.LabelEditor__groupHover : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {withDisplayNames(group.objects).map((childObj, childIndex) =>
                renderObject(childObj, childIndex, true)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Fragment>
    );

    return (
      <div className={styles.LabelEditor__layers}>
        <header>
          <h2>{t("layersLabel")}</h2>
          {/*
          <div className={styles.LabelEditor__layersButtons}>
            <div
              className={styles.LabelEditor__layersButton}
              onClick={addGroup}
            >
              <GroupSVG width={"16px"} height={"16px"} />
            </div>
          </div>
          */}
        </header>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.LabelEditor__objectList}
              >
                {withDisplayNames(objects).map((obj, index) =>
                  obj.type === "group"
                    ? renderGroup(obj, index)
                    : renderObject(obj, index)
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  const handleDeleteNode = (deleteId) => {
    setObjects((prevObjects) => {
      return prevObjects
        .filter((obj) => obj.id !== deleteId)
        .map((obj) => ({
          ...obj,
          ...(obj.objects
            ? { objects: obj.objects.filter((obj) => obj.id !== deleteId) }
            : {}),
        }));
    });
    setSelectedId(null);
  };


  const ObjectExplorer = (
    <div className={styles.LabelEditor__objectExplorer}>
      <header>
        <h2>{t("explorerLabel")}</h2>
        {[
          { id: "rect", SVGComponent: RectSVG, label: t("rectLabel"), addObject: addRect },
          { id: "barcode", SVGComponent: BarcodeSVG, label: t("barcodeLabel"), addObject: addBarcode },
          { id: "text", SVGComponent: TextSVG, label: t("textLabel"), addObject: addText },
          { id: "image", SVGComponent: ImageSVG, label: t("imageLabel"), addObject: addImage },
          //{ id: "gridLayout", SVGComponent: GridLayoutSVG, label: t("gridLayoutLabel"), addObject: addGridLayout },
        ].map(({ id, SVGComponent, label, addObject }) => (
          <div key={id} className={styles.LabelEditor__objectExplorerGroup}>
            <div
              id={id}
              className={`${styles.LabelEditor__svgContainer} ${
                id === "text" ? styles.LabelEditor__objectExplorerTextSVGContainer :
                id === "image" ? styles.LabelEditor__objectExplorerImageSVGContainer :
                styles.LabelEditor__objectExplorerSVGContainer
              }`}
              draggable={true}
              onClick={() => addObject()}
              onDragEnd={(e) => {
                e.preventDefault();
                const stage = stageRef.current.getStage();
                const stageBox = stage.container().getBoundingClientRect();
                
                const x = e.clientX - stageBox.left;
                const y = e.clientY - stageBox.top;

                if (x > 0 && y > 0 && x < stageBox.width && y < stageBox.height) {
                  addObject(x, y);
                }            
              }}
            >
              <div className={styles.LabelEditor__objectExplorerSVGContainer}>
                <SVGComponent />
              </div>
            </div>
            <p className={styles.LabelEditor__objectExplorerTypeName}>{label}</p>
          </div>
        ))}
      </header>
    </div>
  );



  const Properties = () => {
    const selectedObject = selectObject(selectedId);
  
    const propertiesTitle = selectedObject
      ? `${t("propertiesLabel")} - ${t(selectedObject.type + "Label")}`
      : t("labelPropertiesLabel");

    const renderProperties = () => {
      if (!selectedObject) return (
        <StageProperties
          width={labelWidth}
          setWidth={setLabelWidth}
          height={labelHeight}
          setHeight={setLabelHeight}
        />
      )
  
      switch (selectedObject.type) {    
        case 'rect':
          return (
            <RectProperties 
              selectedObject={selectedObject} 
              updateObject={updateObject}
            />
          );
        case 'text':
          return (
            <TextProperties 
              selectedObject={selectedObject} 
              updateObject={updateObject}
              labelType={labelType}
            />
          );    
        case 'image':
          return (
            <ImageProperties 
              selectedObject={selectedObject} 
              updateObject={updateObject}
            />
          );          
        case 'barcode':
          return (
            <BarcodeProperties
              selectedObject={selectedObject} 
              updateObject={updateObject}
              labelType={labelType}
            />
          );    
        case 'group':
          return (
            <GroupProperties 
              selectedObject={selectedObject} 
              updateObject={updateObject}
            />
          );    
        case 'gridLayout':
          return (
            <GridLayoutProperties 
              selectedObject={selectedObject} 
              updateObject={updateObject}
            />
          );
        default:
          return null;
      }
    };
  
    return (
      <div className={styles.LabelEditor__propertiesPanel} style={{height: areaHeight}}>
        <div className={styles.LabelEditor__objectProperties}>
          <header className={styles.LabelEditor__propertiesHeader}>
            <h2>{propertiesTitle}</h2>
            {selectedObject && (
              <div
                className={`${styles.LabelEditor__trashIconContainer} ${
                  selectedObject.locked ? styles.disabled : ""
                }`}
                {...(selectedObject.locked
                  ? {}
                  : { onClick: () => setShowDeleteNodeWarning(true) })}
              >
                <TrashIcon />
              </div>
            )}
          </header>
          {renderProperties()}
        </div>

        <div className={styles.LabelEditor__stageProperties}>
          <header>
            <h2>{t("printProperties")}</h2>
          </header>
          <PrintProperties
            width={sheetWidth}
            setWidth={setSheetWidth}
            height={sheetHeight}
            setHeight={setSheetHeight}
            rowCount={rowCount}
            setRowCount={setRowCount}
            colCount={colCount}
            setColCount={setColCount}
          />
        </div>

        <div className={styles.LabelEditor__tab}>
          <header>
            <h2>{t("areaLabel")}</h2>
          </header>
          <AreaProperties
            zoom={zoom}
            setZoom={setZoom}
            viewerMode={viewerMode}
            setViewerMode={setViewerMode}
          />
        </div>
      </div>
    );
  };


  const handleDeleteKey = (e) => {
    if (
      selectedId &&
      !selectedLock &&
      (e.keyCode === 46 || e.keyCode === 8) &&
      e.target.tagName !== "INPUT"
    ) {
      setShowDeleteNodeWarning(true);
    }
  };

  const DeleteWarning = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.LabelEditor__deleteWarningModal}>
        <p>{t("deleteWarning")}</p>
        <div className={styles.LabelEditor__ModalButtonsGroup}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => setShowDeleteNodeWarning(false)}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("deleteButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              setShowDeleteNodeWarning(false);
              handleDeleteNode(selectedId);
            }}
            isDisabled={error ? true : false}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  const handlePrevious = (overrideLabelModificationInProgress = false) => {
    if (
      !label &&
      !overrideLabelModificationInProgress &&
      labelModificationInProgressViaContext
    ) {
      setShowModal(true);
    } else if (
      label ||
      !labelModificationInProgressViaContext ||
      overrideLabelModificationInProgress
    ) {
      onHideLabelCanvas();
    }
  };

  const Modal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.LabelEditor__deleteWarningModal}>
        <p className={styles.LabelEditor__deleteWarningModalMessage}>
          {t("returnWarning")}
        </p>
        <div className={styles.LabelEditor__ModalButtonsGroup}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("prevButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              handleLabelModificationInProgressViaContext(false);
              setShowModal(false);
              handlePrevious(true);
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  return (
    <Fragment>
      {showModal && Modal}
      <div className={styles.LabelEditor__labelAndButtonsContainer}>
        <header>
          <h2 className={styles.LabelEditor__labelEditorHeading}>
            {t("title")}
          </h2>
        </header>
        <div className={styles.LabelEditor__groupContainer}>
          {showDeleteNodeWarning && DeleteWarning}

          <div
            className={styles.LabelEditor__labelEditorContainer}
            tabIndex="0"
            onKeyDown={(e) => handleDeleteKey(e)}
          >
            <div className={styles.LabelEditor__objectsPanel} style={{height: areaHeight}}>
              {Layers()}
              {ObjectExplorer}
            </div>            
            <div
              id="stageContainer"
              className={styles.LabelEditor__stageContainer}
              style={{
                width: areaWidth,
                height: areaHeight
              }}
            >
              <div 
                id="stage"
                className={styles.LabelEditor__stage}
              >               
                {!viewerMode && <LabelEditorStage   
                  stageRef={stageRef}
                  width={labelWidth}
                  height={labelHeight}
                  zoom={zoom}
                  objects={objects}
                  setObjects={setObjects}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  selectObject={selectObject}
                  updateObject={updateObject}
                  dropObject={dropObject}
                  transformerLock={selectedLock}
                />}
                
                {viewerMode && <LabelViewerStage   
                  stageRef={stageRef}
                  canisterObjects={new Array(rowCount * colCount).fill(objects)}
                  page={0}  
                  zoom={zoom}                
                  sheetWidth={sheetWidth}
                  sheetHeight={sheetHeight}
                  labelWidth={labelWidth}
                  labelHeight={labelHeight}
                  rowCount={rowCount}
                  colCount={colCount}
                  autoLayout={true}
                  previewOutline={false}
                  previewPosition={false}
                />}   
              </div>
            </div>            
            {Properties()}
          </div>
        </div>
        <div className={styles.LabelEditor__buttonsContainer}>
          <Button
            labelName={t("prevButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => handlePrevious()}
            isDisabled={!(error || barcodeValueError) ? false : true}
          />
          <Spacer space={20} unit={"px"} />
          <div
            onClick={
              nodesArrayForUpdate?.length ? handleUpdateLabel : handleSaveLabel
            }
          >
            <Button
              labelName={
                nodesArrayForUpdate?.length ? t("updateButtonLabel") : t("saveButtonLabel")
              }
              isPrimary={true}
              minWidth={"213px"}
              isDisabled={
                objects.length && !(error || barcodeValueError) ? false : true
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LabelEditor;
