import React, { useState, useEffect } from "react";
import styles from "./Properties.module.css";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import { useTranslation } from "react-i18next";
import { ReactComponent as BoldSVG } from "../svgs/text-bold.svg";
import { ReactComponent as ItalicSVG } from "../svgs/text-italic.svg";
import { ReactComponent as UnderlineSVG } from "../svgs/text-underline.svg";
import { ReactComponent as LeftAlignSVG } from "../svgs/text-left2.svg";
import { ReactComponent as CenterAlignSVG } from "../svgs/text-center2.svg";
import { ReactComponent as RightAlignSVG } from "../svgs/text-right2.svg";
import getCanisterDataMappingDropdownList from "./getCanisterDataMappingDropdownList";
import { pxToMm } from "../Converters";

const TextProperties = ({ selectedObject, updateObject, labelType }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;
    value = type === "text" ? e.target.value : Number(e.target.value);

    const linkedValues = {};
    if (name === "fontSize") {
      linkedValues.height = pxToMm(value * selectedObject.wrapLines);
    } else if (name === "wrapLines") {
      linkedValues.height = pxToMm(value * selectedObject.fontSize);
    }

    updateObject({
      ...selectedObject,
      [name]: value,
      ...linkedValues,
    });
  };

  const dropdownListFont = [
    { Item: "Poppins", Value: 1 },
    { Item: "Roboto", Value: 2 },
    { Item: "Open Sans", Value: 3 },
  ];

  const handleSelectedFont = (font) => {
    updateObject({
      ...selectedObject,
      fontFamily: font,
    });
  };

  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleCanisterDataMapping = (friendlyName, path) => {

    updateObject({
      ...selectedObject,
      mappingName: friendlyName,
      mappingPath: path,
    });
  };

  const dropdownListWrap = [
    { Item: "none", Value: 1, displayName: t("noWrapValue") },
    { Item: "word", Value: 2, displayName: t("wordWrapValue") },
    { Item: "char", Value: 3, displayName: t("charWrapValue") },
  ];

  const handleSelectedWrap = (wrap) => {
    updateObject({
      ...selectedObject,
      wrap: wrap,
    });
  };

  const toggleBold = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "bold";
    } else if (fontStyle === "bold") {
      fontStyle = "normal";
    } else if (fontStyle === "italic") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "italic";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleItalic = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "italic";
    } else if (fontStyle === "italic") {
      fontStyle = "normal";
    } else if (fontStyle === "bold") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "bold";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleUnderline = () => {
    if (selectedObject.locked) return;

    updateObject({
      ...selectedObject,
      textDecoration:
        selectedObject.textDecoration === "underline" ? "" : "underline",
    });
  };

  const toggleTextAlignment = (align) => {
    if (selectedObject.locked) return;

    updateObject({
      ...selectedObject,
      align,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")} (mm):
          </label>
          <input
            name="width"
            type="number"
            value={selectedObject.width}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")} (mm):
          </label>
          <input
            name="height"
            type="number"
            value={selectedObject.height}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X (mm):</label>
          <input
            name="x"
            type="number"
            value={selectedObject.x}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y (mm):</label>
          <input
            name="y"
            type="number"
            value={selectedObject.y}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      <div className={styles.Properties__property}>
        <label className={styles.Properties__label}>
          {t("borderColorLabel")}:
        </label>
        <input
          name="stroke"
          type="text"
          value={selectedObject.stroke}
          onChange={handleInputChange}
          className={`${styles.Properties__input} ${
            selectedObject.locked ? styles.disabled : ""
          }`}
          disabled={selectedObject.locked}
        />
      </div>

      {labelType === "Canister" && (
        <div className={styles.Properties__canisterDataMapping}>
          <SFDropDownList
            id={"canisterDataMapping"}
            label={t("canisterDataLabel")}
            defaultValue={selectedObject.mappingName}
            dropDownList={dropdownListCanisterDataMapping}
            onSelection={handleCanisterDataMapping}
            display={"displayName"}
            enabled={!selectedObject.locked}
          />
        </div>
      )}

      <div className={styles.Properties__property}>
        <label className={styles.Properties__label}>
          {t("defaultValueLabel")}:
        </label>
        <input
          name="defaultValue"
          type="text"
          value={selectedObject.defaultValue}
          onChange={handleInputChange}
          className={`${styles.Properties__input} ${
            selectedObject.locked ? styles.disabled : ""
          }`}
          disabled={selectedObject.locked}
        />
      </div>

      <div className={styles.Properties__TextProperties__fontContainer}>
        <SFDropDownList
          id={"fontFamily"}
          label={t("fontLabel")}
          defaultValue={selectedObject.fontFamily}
          dropDownList={dropdownListFont}
          onSelection={handleSelectedFont}
          enabled={!selectedObject.locked}
        />
      </div>

      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("fontSizeLabel")}:
          </label>
          <input
            name="fontSize"
            type="number"
            value={selectedObject.fontSize}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>

        <div>
          <div className={styles.Properties__boxesContainer}>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={toggleBold}
            >
              <BoldSVG />
            </div>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={toggleItalic}
            >
              <ItalicSVG />
            </div>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={toggleUnderline}
            >
              <UnderlineSVG />
            </div>
          </div>

          <div className={styles.Properties__boxesContainer}>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={() => {
                toggleTextAlignment("left");
              }}
            >
              <LeftAlignSVG />
            </div>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={() => {
                toggleTextAlignment("center");
              }}
            >
              <CenterAlignSVG />
            </div>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked ? styles.disabled : ""
              }`}
              onClick={() => {
                toggleTextAlignment("right");
              }}
            >
              <RightAlignSVG />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Properties__wrapContainer}>
        <SFDropDownList
          id={"wrap"}
          label={t("wrapLabel")}
          defaultValue={selectedObject.wrap}
          dropDownList={dropdownListWrap}
          onSelection={handleSelectedWrap}
          display={"displayName"}
          enabled={!selectedObject.locked}
        />
      </div>

      <div className={styles.Properties__property}>
        <label className={styles.Properties__label}>
          {t("maxLinesLabel")}:
        </label>
        <input
          name="wrapLines"
          type="number"
          value={selectedObject.wrapLines}
          onChange={handleInputChange}
          min={1}
          step={1}
          className={`${styles.Properties__input} ${
            selectedObject.locked ? styles.disabled : ""
          }`}
          disabled={selectedObject.locked}
        />
      </div>
    </div>
  );
};

export default TextProperties;
