import React, { useState, useEffect } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import getCanisterDataMappingDropdownList from "./getCanisterDataMappingDropdownList";
import Checkbox from "../../../components/Checkbox";

const BarcodeProperties = ({ selectedObject, updateObject, labelType }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;
    value = type === "text" ? e.target.value : Number(e.target.value);
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  const handleSelectedSymbology = (symbology) => {
    updateObject({
      ...selectedObject,
      symbology: symbology,
    });
  };

  
  const dropdownListSymbology = [
    { Item: "code128", Value: "Code128B", "displayName":  t("codeLabel", {code: "128"}) },
    { Item: "code39", Value: "Code39", "displayName":  t("codeLabel", {code: "39"}) },
    { Item: "datamatrix", Value: "DataMatrix", "displayName":  t("matrixLabel") },
    { Item: "qrcode", Value: "QRBarcode", "displayName":  t("qrLabel") },
  ].sort((a, b) => a.displayName.localeCompare(b.displayName));

  
  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleCanisterDataMapping = (friendlyName, path) => {
    updateObject({
      ...selectedObject,
      mappingName: friendlyName,
      mappingPath: path,
    });
  };
  
  const handleShowValueCheckbox = () => {
    updateObject({
      ...selectedObject,
      showValue: !selectedObject.showValue,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")} (mm):
          </label>
          <input
            name="width"
            type="number"
            value={selectedObject.width}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")} (mm):
          </label>
          <input
            name="height"
            type="number"
            value={selectedObject.height}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X (mm):</label>
          <input
            name="x"
            type="number"
            value={selectedObject.x}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y (mm):</label>
          <input
            name="y"
            type="number"
            value={selectedObject.y}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      <div>
        <SFDropDownList
          id={"symbology"}
          label={t("symbologyLabel")}
          defaultValue={selectedObject.symbology}
          dropDownList={dropdownListSymbology}
          onSelection={handleSelectedSymbology}
          display={"displayName"}
          enabled={!selectedObject.locked}
        />
      </div>

      <div className={styles.Properties__property}>
        <label className={styles.Properties__label}>
          {t("defaultValueLabel")}:
        </label>
        <input
          name="defaultValue"
          type="text"
          value={selectedObject.defaultValues[selectedObject.symbology]}
          onChange={handleInputChange}
          className={`${styles.Properties__input} ${
            selectedObject.locked ? styles.disabled : ""
          }`}
          disabled={selectedObject.locked}
        />
      </div>
      
      {labelType === "Canister" && (
        <div className={styles.Properties__canisterDataMapping}>
          <SFDropDownList
            id={"canisterDataMapping"}
            label={t("canisterDataLabel")}
            defaultValue={selectedObject.mappingName}
            dropDownList={dropdownListCanisterDataMapping}
            onSelection={handleCanisterDataMapping}
            display={"displayName"}
            enabled={!selectedObject.locked}
          />
        </div>
      )}
      
      <div>
        <div className={styles.Properties__toggle}>
          <Checkbox 
            labelName={t("showValueLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.showValue}
            onCheck={handleShowValueCheckbox}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
    </div>
  );
};

export default BarcodeProperties;
