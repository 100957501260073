import React, { useState, useEffect } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import { pxToMm } from "../Converters";
import { placeHolderImage } from "../LabelComponents/placeHolderImage";

const ImageProperties = ({ selectedObject, updateObject }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;
    value = type === "text" ? e.target.value : Number(e.target.value);
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  const onDrop = async (files) => {
    let file = files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      let stringArray = reader.result.split("base64,");

      const img = new Image();
      img.onload = () => {
        updateObject({
          ...selectedObject,
          base64EncodedString: stringArray[1],
          mimeType: `${file.type}`,
          width: pxToMm(img.width),
          height: pxToMm(img.height),
          name: file.name,
        });
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleImageCheckbox = () => {
    const revertPlaceholder = !selectedObject.getImagesFromDatabase ? {      
      mimeType: "image/png",
      base64EncodedString: placeHolderImage,
      width: 7.9,
      height: 7.9,
    } : {}

    updateObject({
      ...selectedObject,
      getImagesFromDatabase: !selectedObject.getImagesFromDatabase,
      name: !selectedObject.getImagesFromDatabase ? t("productImageLabel") : t("customImageLabel"),
      ...revertPlaceholder,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")} (mm):
          </label>
          <input
            name="width"
            type="number"
            value={selectedObject.width}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")} (mm):
          </label>
          <input
            name="height"
            type="number"
            value={selectedObject.height}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X (mm):</label>
          <input
            name="x"
            type="number"
            value={selectedObject.x}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y (mm):</label>
          <input
            name="y"
            type="number"
            value={selectedObject.y}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div>
        <div className={styles.Properties__toggle}>
          <Checkbox 
            labelName={t("fromDatabaseLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.getImagesFromDatabase}
            onCheck={handleImageCheckbox}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      {!selectedObject.getImagesFromDatabase && (
        <div className={styles.editProfile__profilePicButton}>
          <Dropzone
            accept={"image/jpeg, image/png, image/svg+xml"}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ marginTop: "20px" }} {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  labelName="Change Image"
                  isPrimary={true}
                  isInverse={true}
                  isDisabled={selectedObject.locked}
                  minWidth={null}
                  width="100%"
                />
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </div>
  );
};

export default ImageProperties;
