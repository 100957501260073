import React, { useState, useEffect } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";

const GridLayoutProperties = ({ selectedObject, updateObject }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const [rows, setRows] = useState(selectedObject.rows);
  const [cols, setCols] = useState(selectedObject.cols);

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;
    value = type === "text" ? e.target.value : Number(e.target.value);
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  
  const handleRowsChange = (e) => {
    let { name, value } = e.target;
    const newRows = Number(e.target.value);

    const prevRows = selectedObject.objects.length;

    const cells = selectedObject.objects.slice(newRows).flat();
    if (newRows < prevRows && cells.some(cell => cell !== null)) {
      return
    }

    console.log("columns:", selectedObject.cols)

    const newObjects = newRows < prevRows 
      ? selectedObject.objects.slice(0, newRows) 
      : [
          ...selectedObject.objects, 
          ...Array(newRows - prevRows).fill(
            Array(cols).fill(null)
          )
        ];
  

    setRows(newRows)
    updateObject({
      ...selectedObject,
      [name]: value,
      objects: newObjects,      
      name: `${t("gridLayoutLabel")} (${cols}x${newRows})`,
    });
  
  };

  const handleColsChange = (e) => {
    let { name, value } = e.target;
    const newCols = Number(e.target.value);
  
    const prevCols = selectedObject.objects[0].length;
  
    const cells = selectedObject.objects.map(row => row.slice(newCols)).flat();
    if (newCols < prevCols && cells.some(cell => cell!== null)) {
      return;
    }
  
    const newObjects = selectedObject.objects.map(row => {
      return newCols < prevCols
       ? row.slice(0, newCols)
        : [...row,...Array(newCols - prevCols).fill(null)];
    });
  

    setCols(newCols)
    updateObject({
     ...selectedObject,
      [name]: value,
      objects: newObjects,
      name: `${t("gridLayoutLabel")} (${newCols}x${rows})`,
    });
  };


  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")} (mm):
          </label>
          <input
            name="width"
            type="number"
            value={selectedObject.width}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")} (mm):
          </label>
          <input
            name="height"
            type="number"
            value={selectedObject.height}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X (mm):</label>
          <input
            name="x"
            type="number"
            value={selectedObject.x}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y (mm):</label>
          <input
            name="y"
            type="number"
            value={selectedObject.y}
            onChange={handleInputChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      
      <div className={styles.Properties__row}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("rowsLabel")}:</label>
          <input
            name="rows"
            type="number"
            value={selectedObject.rows}
            onChange={handleRowsChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
            min={1}
            step={1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("colsLabel")}:</label>
          <input
            name="cols"
            type="number"
            value={selectedObject.cols}
            onChange={handleColsChange}
            className={`${styles.Properties__input} ${
              selectedObject.locked ? styles.disabled : ""
            }`}
            disabled={selectedObject.locked}
            min={1}
            step={1}
          />
        </div>
      </div>
    </div>
  );
};

export default GridLayoutProperties;
