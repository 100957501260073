import React from 'react';
import styles from './Properties.module.css';
import { useTranslation } from 'react-i18next';
import AntSlider from '../../../components/Ant/AntSlider/AntSlider';
import Checkbox from '../../../components/Checkbox';


const AreaProperties = ({ 
  zoom,
  setZoom,
  viewerMode,
  setViewerMode,
}) => {
  const { t } = useTranslation("labelEditor");

  return (
    <div className={styles.Properties__container}>
      <div>
        <label className={styles.Properties__label}>{t("zoomLabel")}:</label>          
        <AntSlider
          min={10}
          max={300}
          defaultValue={zoom}
          onChange={(e) => setZoom(Number(e))}
        />
      </div>

      <div>
        <div className={styles.Properties__toggle}>
          <Checkbox 
            labelName={t("printPreviewLabel")}
            labelColor={"var(--black)"}
            isOn={viewerMode}
            onCheck={setViewerMode}
          />
        </div>
      </div>       
    </div>
  );
};

export default AreaProperties;
