import Konva from "konva";
import { Image as KonvaImage } from 'react-konva';
import { pxToMm, mmToPx } from "../Converters";
import useImage from 'use-image';



const Image = ({ obj, updateObject, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly })  => {
  const [image] = useImage(`data:${obj.mimeType};base64,${obj.base64EncodedString}`);
  
  if (viewOnly && obj.ignoreImage) {
    return null;
  }

  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};


  if (!obj?.width && image?.width && !obj?.height && image?.height) {
    obj.width = pxToMm(image.width);
    obj.height = pxToMm(image.height);
    updateObject({
      ...obj,
    });
  }
  
  return (
    <KonvaImage 
      objectType={"image"}
      image={image} 
      key={obj.id} 
      id={obj.id} 
      x={mmToPx(obj.x)}
      y={mmToPx(obj.y)}
      width={obj?.width ? mmToPx(obj.width) : 0}
      height={obj?.height ? mmToPx(obj.height) : 0}         
      rotation={obj.rotation}  
      filters={[Konva.Filters.Blur]}
      blurRadius={1}  
      imageSmoothingEnabled={true} 
      listening={!viewOnly}
      locked={obj.locked}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
    />
  );
};

export default Image;